<template>
  <div>
    <b-button
      v-b-modal.roteiroModal
      class="ml-2"
      variant="primary"
    >
      Cadastrar Roteiro
    </b-button>
    <b-card>
      <b-table
        :items="items"
        :fields="fields"
      >
        <template #cell(status)="data">
          <b-badge :variant="data.item.status ? 'success' : 'danger'">
            {{ data.item.status ? 'ATIVO' : 'INATIVO' }}
          </b-badge>
        </template>
        <template #cell(acoes)="data">
          <b-row align-h="center">
            <b-col cols="auto">
              <feather-icon
                icon="ToolIcon"
                class="cursor-pointer"
                @click="editRoteiro(data.item.id)"
              />
            </b-col>
            <b-col cols="auto">
              <feather-icon
                icon="XIcon"
                class="iconeX cursor-pointer"
                @click="removeRoteiro(data.item.id)"
              />
            </b-col>
          </b-row>
        </template>
      </b-table>
    </b-card>
    <b-modal
      id="roteiroModal"
      ref="roteiroModal"
      :title="roteiroID ? 'Editar Roteiro' : 'Cadastrar Roteiro'"
      :ok-title=" roteiroID ? 'Atualizar' : 'Cadastrar'"
      cancel-title="Cancelar"
      cancel-variant="danger"
      no-close-on-backdrop
      @cancel="clearForm"
      @ok="roteiroID ? updateRoteiro() : submit()"
    >
      <b-row>
        <b-col>
          <label for=""><strong>Título: </strong></label>
          <b-form-input
            v-model="form.titulo"
            type="text"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label for=""><strong>mensagem: </strong></label>
          <b-form-textarea
            v-model="form.mensagem"
            type="text"
          />
        </b-col>
      </b-row>
      <b-row v-if="roteiroID">
        <b-col>
          <label for=""><strong>Status : </strong></label>
          <b-form-checkbox
            v-model="form.status"
            :checked="form.status"
            class="custom-control-success ml-3"
            name="check-button"
            switch
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { BCol, BRow, BFormInput, BCard, BTable, BButton, BBadge, BFormCheckbox, BFormTextarea } from 'bootstrap-vue'
import axios from '@/../axios-auth'

export default {
  components: {
    BCol,
    BRow,
    BFormInput,
    BCard,
    BTable,
    BButton,
    BBadge,
    BFormCheckbox,
    BFormTextarea,
  },
  data() {
    return {
      items: [],
      roteiroID: null,
      fields: [
        { key: 'titulo', label: 'TITULO', class: 'text-center' },
        { key: 'mensagem', label: 'mensagem', class: 'text-center' },
        { key: 'status', label: 'status', class: 'text-center' },
        { key: 'acoes', label: 'AÇÕES', class: 'text-center' },
      ],
      form: {
        titulo: '',
        mensagem: '',
        status: true,
      },
    }
  },
  created() {
    this.getRoteiros()
  },
  methods: {
    clearForm() {
      this.form.titulo = ''
      this.form.mensagem = ''
      this.form.status = true
      this.roteiroID = null
    },
    submit() {
      axios.post('api/v1/roteiro/store', this.form, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(() => {
          this.clearForm()
          this.getRoteiros()
          this.$swal({
            title: 'Roteiro Cadastrado com sucesso!',
            icon: 'success',
            timer: 1500,
            showConfirmButton: false,
          })
        })
        .catch(() => {
          this.clearForm()
          this.getRoteiros()
          this.$swal({
            title: 'Ops, erro ao cadastrar Roteiro =(',
            icon: 'danger',
            timer: 1500,
            showConfirmButton: false,
          })
        })
    },
    getRoteiros() {
      this.items = []
      axios.get('api/v1/roteiro/', {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(res => {
          res.data.dados.map(item => {
            this.items.unshift(item)
          })
        })
    },
    editRoteiro(id) {
      this.roteiroID = id
      axios.get(`api/v1/roteiro/edit/${id}`, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(res => {
          this.form = {
            titulo: res.data.dados.titulo,
            mensagem: res.data.dados.mensagem,
            status: res.data.dados.status,
          }
          this.$refs.roteiroModal.show()
        })
        .catch(() => {
          this.clearForm()
          this.$swal({
            title: 'Ops, algo deu errado =(',
            icon: 'danger',
            timer: 1500,
            showConfirmButton: false,
          })
        })
    },
    updateRoteiro() {
      axios.put(`api/v1/roteiro/update/${this.roteiroID}`, this.form, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(() => {
          this.clearForm()
          this.getRoteiros()
          this.$swal({
            title: 'Roteiro atualizado com sucesso!',
            icon: 'success',
            timer: 1500,
            showConfirmButton: false,
          })
        })
        .catch(() => {
          this.clearForm()
          this.getRoteiros()
          this.$swal({
            title: 'Ops, algo não foi possível atualizar =(',
            icon: 'danger',
            timer: 1500,
            showConfirmButton: false,
          })
        })
    },
    removeRoteiro(id) {
      this.$swal({
        title: 'Deseja excluir esse Roteiro?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, excluir!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios.delete(`api/v1/roteiro/delete/${id}`, {
            headers: {
              Authorization: 'Bearer '.concat(localStorage.getItem('token')),
              accept: 'application/json',
            },
          })
            .then(() => {
              this.clearForm()
              this.getRoteiros()
              this.$swal({
                title: 'Roteiro deletado com sucesso!',
                icon: 'success',
                timer: 1500,
                showConfirmButton: false,
              })
            })
            .catch(() => {
              this.clearForm()
              this.getRoteiros()
              this.$swal({
                title: 'Ops, algo deu errado',
                icon: 'danger',
                timer: 1500,
                showConfirmButton: false,
              })
            })
        }
      })
    },
  },
}
</script>

<style>
.iconeX {
  color: red;
}
</style>

<template>
  <div>
    <b-row
      class="mb-2"
      align-h="between"
    >
      <b-col>
        <b-button
          variant="primary"
          @click="$refs.modalFuncionario.show(), newFuncionario=true, limparModal(), edit=false"
        >
          Novo Funcionário
        </b-button>
      </b-col>
    </b-row>
    <b-modal
      id="usuario"
      ref="usuario"
      title="Criar Usuário"
      ok-title="Criar Usuário"
      cancel-title="Cancelar"
      cancel-variant="danger"
      @ok="novoUsuario"
      @show="limparModal"
    >
      <b-row>
        <b-col
          cols="12"
          class="mb-2"
        >
          Nome:
          <b-form-input
            v-model="user.name"
          />
        </b-col>
        <b-col
          cols="12"
          class="mb-2"
        >
          Email
          <b-form-input
            v-model="user.email"
            placeholder=""
          />
        </b-col>
        <b-col
          cols="12"
          class="mb-2"
        >
          CPF:
          <cleave
            v-model="user.cpf"
            class="form-control"
            :raw="false"
            :options="mask.cpf"
            placeholder="000.000.000-00"
          />
        </b-col>
        <b-col
          cols="12"
          class="mb-2"
        >
          Senha:
          <b-form-input
            v-model="user.password"
            type="password"
          />
        </b-col>
        <b-col
          cols="12"
          class="mb-2"
        >
          Empresa:
          <b-form-select
            v-model="user.empresa_id"
            :options="empresas"
          />
        </b-col>
        <b-col
          cols="12"
          class="mb-2"
        >
          Perfil:
          <b-form-select
            v-model="user.perfil_usuario_id"
            :options="optionsPerfil"
          />
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      ref="modalFuncionario"
      hide-footer
    >
      <b-row>
        <b-col
          cols="12"
          class="mb-1"
        >
          Nome:
          <b-form-input
            v-model="cad.nome"
            :disabled="edit"
          />
        </b-col>
        <b-col
          cols="12"
          class="mb-1"
        >
          CPF:
          <cleave
            v-model="cad.cpf"
            class="form-control"
            :raw="false"
            :options="mask.cpf"
            placeholder="000.000.000-00"
            :disabled="edit"
          />
        </b-col>
        <b-col
          cols="12"
          class="mb-1"
        >
          RG:
          <b-form-input
            v-model="cad.rg"
            :disabled="edit"
          />
        </b-col>
        <b-col
          cols="12"
          class="mb-1"
        >
          Endereço:
          <b-form-input
            v-model="cad.endereco"
            :disabled="edit"
          />
        </b-col>
        <b-col
          cols="12"
          class="mb-1"
        >
          Observação:
          <b-form-input
            v-model="cad.observacao"
            :disabled="edit"
          />
        </b-col>
        <b-col
          cols="12"
          class="mb-1"
        >
          Cidade:
          <b-form-select
            v-model="cad.cidade_id"
            :options="cidades"
            :disabled="edit"
          />
        </b-col>
        <b-col
          cols="12"
          class="mb-1"
        >
          Função:
          <b-form-select
            v-model="cad.funcao_id"
            :options="funcoes"
            :disabled="edit"
          />
        </b-col>
        <b-col
          cols="12"
          class="mb-1"
        >
          Empresa:
          <b-form-select
            v-model="cad.usuario_empresas_id"
            :options="empresas"
            :disabled="edit"
          />
        </b-col>
        <b-col
          cols="12"
          class="mb-1"
        >
          Status:
          <b-form-select
            v-model="cad.status"
            :options="statusOptions"
            :disabled="edit"
          />
        </b-col>
      </b-row>
      <hr>
      <b-row
        v-if="newFuncionario"
        align-h="end"
        class="mt-1"
      >
        <b-col cols="auto">
          <b-button
            variant="danger"
            @click="$refs.modalFuncionario.hide()"
          >
            Cancelar
          </b-button>
        </b-col>
        <b-col cols="auto">
          <b-button
            variant="primary"
            @click="novoFuncionario"
          >
            Cadastrar
          </b-button>
        </b-col>
      </b-row>
      <b-row
        v-if="!newFuncionario"
        align-h="between"
        class="mt-1"
      >
        <b-col>
          <b-button
            v-show="edit"
            variant="outline-primary"
            @click="edit=false"
          >
            Editar
          </b-button>
        </b-col>
        <b-col>
          <b-button
            variant="outline-danger"
            @click="deleteFuncionario"
          >
            Excluir
          </b-button>
        </b-col>
        <b-col>
          <b-button
            variant="danger"
            @click="$refs.modalFuncionario.hide()"
          >
            Cancelar
          </b-button>
        </b-col>
        <b-col>
          <b-button
            v-show="!edit"
            variant="primary"
            @click="updateFuncionario"
          >
            Salvar
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-card>
      <b-row>
        <b-col
          cols="12"
        >
          <b-row
            align-h="between"
            class="mb-2"
          >
            <b-col lg="1">
              <b-form-select
                v-model="perPage"
                :options="[10, 15, 20]"
                size="sm"
              />
            </b-col>
            <b-col
              lg="8"
              class="d-flex justify-content-end"
            >
              <b-form-input
                v-model="filter"
                size="sm"
                placeholder="Pesquisar"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12">
          <b-table
            responsive
            bordered
            hover
            :per-page="perPage"
            :filter="filter"
            :current-page="currentPage"
            :fields="fields"
            :items="funcionarios"
            @filtered="onFiltered"
          >
            <template #cell(status)="data">
              <b-badge
                :variant="data.item.status ? 'light-success' : 'light-danger'"
              >
                {{ data.item.status ? 'Ativo': 'Inativo' }}
              </b-badge>
            </template>
            <template #cell(acoes)="data">
              <b-row>
                <b-col>
                  <feather-icon
                    class="plus-icon"
                    size="16"
                    icon="PlusIcon"
                    @click="rowClicked(data.item)"
                  />
                </b-col>
                <b-col>
                  <feather-icon
                    :id="`view-${data.item.id}`"
                    class="color-icon"
                    size="16"
                    icon="EditIcon"
                    @click="editFuncionario(data.item)"
                  />
                </b-col>
                <b-col>
                  <feather-icon
                    class="color-icon"
                    size="16"
                    icon="HeadphonesIcon"
                    @click="novoExecutivo(data.item)"
                  />
                </b-col>
                <b-col>
                  <feather-icon
                    v-b-tooltip.hover.top="'Lista de Credores'"
                    class="color-icon"
                    size="16"
                    icon="EyeIcon"
                    @click="showListCredor(data.item.id)"
                  />
                </b-col>
              </b-row>
            </template>
          </b-table>
        </b-col>
        <b-col cols="12">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            size="sm"
          />
        </b-col>
      </b-row>
    </b-card>
    <b-modal
      ref="executivoCobranca"
      title="Exceutivo de Cobrança"
      hide-footer
    >
      <NovoExecutivo
        :item="itemFuncionario"
        :close-modal="closeModal"
      />
    </b-modal>
    <b-modal
      ref="modalListCredor"
      title="Credores"
    >
      <div class="profile-star ml-auto">
        <feather-icon
          icon="PlusIcon"
          size="18"
          class="ml-1 cursor-pointer color-icon"
          @click="addCredor(userId)"
        />
      </div>
      <div
        v-for="(cred, index) in listCredor"
        :key="index"
        class="d-flex justify-content-between align-items-center mt-1"
      >
        <div class="profile-user-info">
          <h6 class="mb-0">
            {{ cred.nome }}
          </h6>
        </div>
        <feather-icon
          icon="XIcon"
          size="18"
          class="ml-1 cursor-pointer color-icon"
          @click="removeCredor(cred)"
        />
      </div>
    </b-modal>
    <b-modal
      ref="modalAddCredor"
      title="Adicionar Credor"
      ok-title="Adicionar"
      @ok="submitCredor(userId)"
    >
      <b-row class="mb-1">
        <b-col md="12">
          <label for="">Selecione o credor</label>
          <v-select
            v-model="credor"
            :options="credores"
            multiple
            label="nome"
          />
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BBadge,
  BCol,
  BButton,
  BFormInput,
  BFormSelect,
  BModal,
  VBModal,
  BTable,
  BCard,
  BPagination,
  VBTooltip,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from '@/../axios-auth'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import NovoExecutivo from './NovoExecutivo.vue'

export default {
  components: {
    BRow,
    BCol,
    BBadge,
    BButton,
    BFormInput,
    BFormSelect,
    BModal,
    BTable,
    BCard,
    BPagination,
    Cleave,
    FeatherIcon,
    NovoExecutivo,
    vSelect,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      itemFuncionario: {},
      funcionarioId: '',
      newFuncionario: true,
      edit: false,
      statusOptions: [{ value: true, text: 'Ativo' },
        { value: false, text: 'Inativo' },
      ],
      cad: {
        nome: '',
        cpf: 61445370310,
        rg: 123456789,
        endereco: '',
        observacao: '',
        cidade_id: null,
        funcao_id: '',
        usuario_empresas_id: 49,
        status: true,
      },
      user: {
        name: null,
        email: null,
        cpf: null,
        password: null,
        url_perfil: null,
        status: true,
        empresa_id: null,
        perfil_usuario_id: null,
      },
      mask: {
        cpf: {
          delimiters: ['.', '.', '-'],
          blocks: [3, 3, 3, 2],
          uppercase: true,
        },
      },

      // "email":"hadassa@grupovoz.com.br",
      // "password":"123456789",
      // "url_perfil":"teste_url",

      // "name":"Hadassa Bandeira",
      // "cpf":"61445370310",
      // "status":true,
      // "empresa_id":1

      funcoes: [],
      cidades: [],
      empresas: [],
      fields: [
        'nome',
        'cpf',
        'funcao',
        { key: 'status', label: 'Status' },
        { key: 'acoes', label: 'ações', class: 'd-flex' },
      ],
      filter: '',
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
      funcionarios: [],
      listCredor: [],
      credor: [],
      credores: [],
      userId: null,
      optionsPerfil: [],
    }
  },
  created() {
    this.listaFuncoes()
    this.listaFuncionarios()
    this.listaCidade()
    this.listaEmpresa()
    this.getCredores()
    this.getPerfils()
  },
  methods: {
    closeModal() {
      this.$refs.executivoCobranca.hide()
    },
    novoExecutivo(item) {
      this.itemFuncionario = {}
      this.$refs.executivoCobranca.show()
      this.itemFuncionario = item
    },
    limparModal() {
      this.cad = {
        nome: null,
        cpf: null,
        rg: null,
        endereco: null,
        observacao: null,
        cidade_id: null,
        funcao_id: null,
        grupo_acesso_id: null,
        usuario_empresas_id: null,
        status: true,
      }
      this.user = {
        name: null,
        email: null,
        cpf: null,
        password: null,
        url_perfil: null,
        status: true,
        empresa_id: null,
      }
    },
    async listaEmpresa() {
      await axios.get('api/v1/empresas', {
        headers: {
          Accept: 'Application/json',
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        },
      }).then(res => {
        res.data.dados.empresa.map(dt => {
          this.empresas.push({
            text: dt.nome_fantasia,
            value: dt.id,
          })
        })
      })
    },
    async listaCidade() {
      await axios.get('api/v1/cidades', {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(res => {
        res.data.dados.map(cidade => {
          this.cidades.push({
            value: cidade.id,
            text: cidade.nome,
          })
        })
      })
    },

    async listaFuncionarios() {
      this.funcionarios = []
      await axios.get('api/v1/funcionarios', {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(res => {
        res.data.dados.map(dt => {
          this.funcionarios.unshift({
            id: dt.id,
            nome: dt.nome,
            cpf: dt.cpf,
            funcao: dt.funcao.nome,
            status: dt.status,
          })
        })
        this.totalRows = this.funcionarios.length
      })
    },
    async updateFuncionario() {
      const body = {
        nome: this.cad.nome,
        cpf: this.cad.cpf.replace(/[^0-9]/g, ''),
        rg: this.cad.rg.replace(/[^0-9]/g, ''),
        endereco: this.cad.endereco,
        observacao: this.cad.observacao,
        cidade_id: this.cad.cidade_id,
        funcao_id: this.cad.funcao_id,
        usuario_empresas_id: this.cad.usuario_empresas_id,
        status: this.cad.status,
      }
      await axios.put(`api/v1/funcionarios/update/${this.funcionarioId}`, body, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/jason',
        },
      }).then(() => {
        this.$refs.modalFuncionario.hide()
        this.listaFuncionarios()
        this.$swal({
          icon: 'success',
          title: 'Salvo!',
          text: 'Editado com sucesso!',
          timer: 1500,
          showConfirmButton: false,
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
      }).catch(error => {
        this.$swal({
          title: 'Erro ao Salvar!',
          text: error,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
    },
    deleteFuncionario() {
      this.$swal({
        title: 'Tem certeza?',
        text: `Deseja deletar o funcionario ${this.cad.nome}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, quero deletar!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios.delete(`api/v1/funcionarios/delete/${this.funcionarioId}`, {
            headers: {
              Authorization: 'Bearer '.concat(localStorage.getItem('token')),
              accept: 'application/json',
            },
          })
            .then(res => {
              if (res.data) {
                this.$refs.modalFuncionario.hide()
                this.listaFuncionarios()
                this.$swal({
                  icon: 'success',
                  title: 'Deletado!',
                  text: 'Funcionario deletado com sucesso!',
                  timer: 1500,
                  showConfirmButton: false,
                })
              }
            })
            .catch(error => {
              if (error.response) {
                this.$swal({
                  title: 'Erro ao deletar Credor!',
                  text: 'Algo deu errado',
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
              } else {
                // Something happened in setting up the request that triggered an Error
              }
            })
        }
      })
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    async listaFuncoes() {
      this.funcoes = []
      await axios.get('api/v1/funcao', {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(res => {
        res.data.dados.map(dt => {
          this.funcoes.push({
            text: dt.nome,
            value: dt.id,
          })
        })
      })
    },

    rowClicked(item) {
      this.$refs.usuario.show()
      this.user = {
        name: item.nome,
        email: null,
        cpf: item.cpf,
        password: null,
        url_perfil: null,
        status: true,
        empresa_id: item.usuario_empresas_id,
      }
    },
    editFuncionario(item) {
      this.funcionarioId = item.id
      axios.get(`api/v1/funcionarios/edit/${item.id}`, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/jason',
        },
      }).then(res => {
        this.cad = res.data.dados.funcionario
      })
      this.edit = true
      this.newFuncionario = false
      this.$refs.modalFuncionario.show()
    },

    async novoUsuario() {
      const body = {
        name: this.user.name,
        email: this.user.email,
        cpf: (this.user.cpf).replace(/[^0-9]/g, ''),
        password: this.user.password,
        url_perfil: this.user.url_perfil,
        status: true,
        empresa_id: this.user.empresa_id,
        perfil_usuario_id: this.user.perfil_usuario_id,
      }
      axios.post('api/v1/users/store', body, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(() => {
        this.$swal({
          title: 'CADASTRADO!',
          text: 'Usuário cadastrado com sucesso',
          icon: 'success',
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            comfirmButton: 'btn btn-ptimary',
          },
        })
        this.listaFuncionarios()
      }).catch(error => {
        this.$swal({
          title: 'ERROR!',
          text: error.response.data.mensagem,
          icon: 'error',
          customClass: {
            comfirmButton: 'btn btn-danger',
          },
        })
      })
    },

    novoFuncionario() {
      const body = {
        nome: this.cad.nome,
        cpf: this.cad.cpf.replace(/[^0-9]/g, ''),
        rg: this.cad.rg.replace(/[^0-9]/g, ''),
        endereco: this.cad.endereco,
        observacao: this.cad.observacao,
        cidade_id: this.cad.cidade_id,
        funcao_id: this.cad.funcao_id,
        usuario_empresas_id: this.cad.usuario_empresas_id,
        status: this.cad.status,
      }
      axios.post('api/v1/funcionarios/store', body, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(() => {
        this.$swal({
          title: 'CADASTRADO!',
          text: 'Funcionário cadastrado com sucesso',
          icon: 'success',
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            comfirmButton: 'btn btn-ptimary',
          },
        })
        this.$refs.modalFuncionario.hide()
        this.listaFuncionarios()
      }).catch(() => {
        this.$swal({
          title: 'ERROR!',
          text: 'Erro ao cadsastrar funcionário!',
          icon: 'error',
          customClass: {
            comfirmButton: 'btn btn-danger',
          },
        })
      })
    },
    async showListCredor(id) {
      this.listCredor = []
      this.userId = id
      this.$refs.modalListCredor.show()
      const credores = []
      await axios
        .get(`api/v1/users/dados/usuario/${id}`, {
          headers: {
            Authorization: 'Bearer '.concat(localStorage.getItem('token')),
            accept: 'application/json',
          },
        })
        .then(res => {
          res.data.map(item => {
            if (item.credores !== null) {
              credores.push(item.credores)
            }
          })
        })
      if (credores.length >= 1) {
        // convertendo a propriedade da api em um array
        const objetoCred = JSON.parse(credores)
        const arrayList = Object.values(objetoCred)
        const credorList = []
        arrayList.map(item => {
          item.map(itens => {
            credorList.push(itens)
          })
        })
        this.listCredor = credorList
      }
    },
    getCredores() {
      axios
        .get('api/v1/credores/', {
          headers: {
            Authorization: 'Bearer '.concat(localStorage.getItem('token')),
            accept: 'application/json',
          },
        })
        .then(res => {
          const credores = res.data.dados.map(item => ({
            id: item.id,
            cnpj: item.cnpj,
            nome: item.nome,
            razao: item.razao_social,
          }))
          credores.sort((a, b) => a.nome.localeCompare(b.nome)) // ordena os credores pelo nome em ordem alfabética
          this.credores = credores
        })
    },
    addCredor(id) {
      this.userId = id
      this.$refs.modalAddCredor.show()
    },
    submitCredor(id) {
      let body
      if (this.listCredor.length) {
        body = {
          dados: {
            credores: this.credor.concat(this.listCredor),
          },
        }
      } else {
        body = {
          dados: {
            credores: this.credor,
          },
        }
      }
      axios.post(`api/v1/perfil_usuario/dados/credor/${id}`, body, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Adicionado',
              icon: 'BellIcon',
              text: 'Credor adicionado com sucesso!',
              variant: 'success',
            },
          }, {
            position: 'top-center',
            timeout: 1500,
          })
          this.credor = []
          this.showListCredor(id)
        })
        .catch(error => {
          this.$swal({
            title: 'Error! Algo inesperado aconteceu',
            text: error.response.data.mensagem,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
    },
    removeCredor(credor) {
      const removelist = this.listCredor.filter(item => item !== credor)
      const body = {
        dados: {
          credores: removelist,
        },
      }
      axios.post(`api/v1/perfil_usuario/dados/credor/${this.userId}`, body, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Removido',
              icon: 'BellIcon',
              text: 'Credor removido com sucesso!',
              variant: 'success',
            },
          }, {
            position: 'top-center',
            timeout: 1500,
          })
          this.showListCredor(this.userId)
        })
        .catch(error => {
          this.$swal({
            title: 'Error! Algo inesperado aconteceu',
            text: error.response.data.mensagem,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
    },
    async getPerfils() {
      await axios.get('api/v1/perfil_usuario/', {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(res => {
          res.data.dados.map(dt => {
            this.optionsPerfil.unshift({
              value: dt.id,
              text: dt.descricao,
            })
          })
        })
    },
  },
}
</script>

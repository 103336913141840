<template>
  <div>
    <b-modal
      id="cadastrarCidade"
      title="Cadastrar Cidade"
      ok-title="Cadastrar"
      cancel-title="Cancelar"
      @show="limparCampos"
      @ok="salvarCidade"
    >
      <b-row class="ml-1">
        <b-col
          cols="12"
        >
          <b-form-group
            label="Cidade :"
          >
            <b-form-input
              v-model="nome"
              list="my-list-uf"
              uppercase
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
        >
          <b-form-group
            label="Estado :"
          >
            <b-form-select
              v-model="uf"
              :options="estados"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
        >
          <b-form-group
            label="Código IBGE :"
          >
            <b-form-input
              v-model="codigo_ibge"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
    <b-button
      v-b-modal.cadastrarCidade
      variant="primary"
      class="mb-2"
    >
      Cadastrar Cidade
    </b-button>
    <b-card>
      <b-row>
        <b-col>
          <b-row align-h="between">
            <b-col
              lg="1"
              class="mb-2"
            >
              <b-form-select
                v-model="perPage"
                :options="[10, 15, 20]"
                size="sm"
              />
            </b-col>
            <b-col
              lg="8"
              class="justify-content-end"
            >
              <b-form-input
                v-model="filter"
                placeholder="Pesquisar Cidade"
                type="search"
                size="sm"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12">
          <b-table
            hover
            striped
            bordered
            responsive
            select-mode="single"
            :filter="filter"
            :current-page="currentPage"
            :fields="fieldsCidades"
            :items="itemsCidades"
            :per-page="perPage"
            @row-clicked="onRowSelected"
            @filtered="onFiltered"
          />
        </b-col>
        <b-col>
          <b-pagination
            v-model="currentPage"
            :per-page="perPage"
            :total-rows="totalRows"
            align="center"
            size="sm"
          />
        </b-col>
      </b-row>
    </b-card>
    <b-modal
      id="modalCidade"
      ref="modalCidade"
      title="Editar Cidade"
      hide-footer
    >
      <b-row v-if="!editar">
        <b-col>
          <b-form-group
            label="Cidade"
          >
            <b-form-input
              v-model="cidade.nome"
              readonly
            />
          </b-form-group>
          <b-form-group
            label="Estado"
          >
            <b-form-input
              v-model="cidade.uf"
              readonly
            />
          </b-form-group>
          <b-form-group
            label="Código IBGE"
          >
            <b-form-input
              v-model="cidade.codigo_ibge"
              readonly
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="editar">
        <b-col>
          <b-form-group
            label="Cidade"
          >
            <b-form-input
              v-model="cidade.nome"
            />
          </b-form-group>
          <b-form-group
            label="Estado"
          >
            <b-form-input
              v-model="cidade.uf"
            />
          </b-form-group>
          <b-form-group
            label="Código IBGE"
          >
            <b-form-input
              v-model="cidade.codigo_ibge"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row align-h="between">
        <b-col>
          <b-button
            v-show="!editar"
            variant="outline-primary"
            @click="btEditarCidade"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
            />
            <span>Editar</span>
          </b-button>
        </b-col>
        <b-col>
          <b-button
            variant="outline-danger"
            @click="deleteCidade(cidade.id, cidade.nome)"
          >
            <feather-icon
              icon="TrashIcon"
              class="mr-50"
            />
            <span>Excluir</span>
          </b-button>
        </b-col>
        <b-col>
          <b-button
            variant="outline-secondary"
            @click="$refs.modalCidade.hide()"
          >
            <feather-icon
              icon="XIcon"
              class="mr-50"
            />
            <span>Cancelar</span>
          </b-button>
        </b-col>
        <b-col>
          <b-button
            v-show="editar"
            variant="primary"
          >
            <feather-icon
              icon="SaveIcon"
              @click="updateCidade(cidade.id)"
            />
            <span>Salvar</span>
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
import {
  BRow,
  BCard,
  BCol,
  BFormInput,
  BFormGroup,
  BFormSelect,
  BTable,
  BPagination,
  BButton,
  BModal,
  VBModal,
} from 'bootstrap-vue'
import axios from '@/../axios-auth'

export default {
  components: {
    BRow,
    BCard,
    BCol,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BTable,
    BPagination,
    BButton,
    BModal,
  },

  directive: {
    'b-modal': VBModal,
  },

  data() {
    return {
      editar: false,
      filter: null,
      perPage: 15,
      currentPage: 1,
      totalRows: '',
      nome: '',
      uf: '',
      codigo_ibge: '',
      fieldsCidades: [
        { key: 'nome', sortable: true },
        { key: 'ibge' },
        { key: 'uf', sortable: true },
      ],
      cidade: [],
      itemsCidades: [],
      estados: [
        { value: 'AC', text: 'Acre' },
        { value: 'AL', text: 'Alagoas' },
        { value: 'AP', text: 'Amapá' },
        { value: 'AM', text: 'Amazonas' },
        { value: 'BA', text: 'Bahia' },
        { value: 'CE', text: 'Ceará' },
        { value: 'DF', text: 'Distrito Federal' },
        { value: 'ES', text: 'Espírito Santo' },
        { value: 'GO', text: 'Goiás' },
        { value: 'MA', text: 'Maranhão' },
        { value: 'MT', text: 'Mato Grosso' },
        { value: 'MS', text: 'Mato Grosso do Sul' },
        { value: 'MG', text: 'Minas Gerais' },
        { value: 'PA', text: 'Pará' },
        { value: 'PB', text: 'Paraíba' },
        { value: 'PR', text: 'Paraná' },
        { value: 'PE', text: 'Pernambuco' },
        { value: 'PI', text: 'Piauí' },
        { value: 'RJ', text: 'Rio de Janeiro' },
        { value: 'RN', text: 'Rio Grande do Norte' },
        { value: 'RS', text: 'Rio Grande do Sul' },
        { value: 'RO', text: 'Rondônia' },
        { value: 'RR', text: 'Roraima' },
        { value: 'SC', text: 'Santa Catarina' },
        { value: 'SP', text: 'São Paulo' },
        { value: 'SE', text: 'Sergipe' },
        { value: 'TO', text: 'Tocantins' },
      ],
    }
  },

  created() {
    this.listarCidades()
  },

  methods: {

    onRowSelected(item) {
      this.editar = false
      this.getCidade(item.id)
    },

    listarCidades() {
      this.itemsCidades = []
      this.totalRows = null
      axios.get('api/v1/cidades', {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(res => {
        // eslint-disable-next-line array-callback-return
        res.data.dados.map(cidade => {
          this.itemsCidades.push({
            id: cidade.id,
            nome: cidade.nome,
            uf: cidade.uf,
            ibge: cidade.codigo_ibge,
          })
        })
        this.totalRows = this.itemsCidades.length
      })
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    getCidade(id) {
      this.cidade = []
      axios.get(`api/v1/cidades/edit/${id}`, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(res => {
        const dt = res.data.dados.cidade
        this.cidade = {
          id: dt.id,
          nome: dt.nome,
          uf: dt.uf,
          codigo_ibge: dt.codigo_ibge,
        }
      })
      this.editar = false
      this.$refs.modalCidade.show()
    },

    limparCampos() {
      this.nome = ''
      this.uf = ''
      this.codigo_ibge = ''
    },

    salvarCidade() {
      axios.post('api/v1/cidades/store', { nome: this.nome, uf: this.uf, codigo_ibge: this.codigo_ibge }, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(res => {
        if (res.data.dados) {
          this.listarCidades()
          this.$refs.modalCidade.hide()
          this.$swal({
            icon: 'success',
            title: 'Cadastrado!',
            text: 'Cidade cadastrada com sucesso!',
            timer: 1500,
            showConfirmButton: false,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      }).catch(error => {
        if (error.response) {
          this.$swal({
            title: 'Erro ao adicionar!',
            text: error.response.data.mensagem,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
        } else {
          // Something happened in setting up the request that triggered an Error
        }
      })
    },

    updateCidade(id) {
      axios.put(`api/v1/cidades/update/${id}`, { nome: this.cidade.nome, uf: this.cidade.uf, codigo_ibge: this.cidade.codigo_ibge }, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(res => {
        if (res.data.dados) {
          this.listarCidades()
          this.$refs.modalCidade.hide()
          this.$swal({
            icon: 'success',
            title: 'Ataulizado!',
            text: 'Cidade atualizada com sucesso!',
            timer: 1500,
            showConfirmButton: false,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      }).catch(error => {
        if (error.response) {
          this.$swal({
            title: 'Erro ao atualizar!',
            text: error.response.data.mensagem,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
        } else {
          // Something happened in setting up the request that triggered an Error
        }
      })
    },
    deleteCidade(id, nome) {
      this.$swal({
        title: 'Tem certeza?',
        text: `Deseja deletar a cidade ${nome}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim quero Deletar!',
        customClass: {
          cancelButton: 'btn btn-primary ml-1',
          confirmButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          await axios.delete(`api/v1/cidades/delete/${id}`, {
            headers: {
              Authorization: 'Bearer '.concat(localStorage.getItem('token')),
              accept: 'application/json',
            },
          }).then(res => {
            if (res.data.dados) {
              this.listarCidades()
              this.$refs.modalCidade.hide()
              this.$swal({
                icon: 'success',
                title: 'Deletado!',
                text: 'Cidade deletada com sucesso!',
                timer: 1500,
                showConfirmButton: false,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            }
          }).catch(error => {
            if (error.response) {
              this.$swal({
                title: 'Erro ao deletar!',
                text: error.response.data.mensagem,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
            } else {
              // Something happened in setting up the request that triggered an Error
            }
          })
        }
      })
    },
    btEditarCidade() {
      this.editar = true
    },
  },
}
</script>

<template>
  <div>
    <b-tabs
      vertical
      pills
    >
      <b-tab>
        <template #title>
          <span class="font-weight-bold">Funcionários</span>
        </template>
        <FuncionarioCadastro />
      </b-tab>
      <b-tab lazy>
        <template #title>
          <span class="font-weight-bold">Cidades</span>
        </template>
        <CidadeCadastro />
      </b-tab>
      <b-tab lazy>
        <template #title>
          <span class="font-weight-bold">Empresas</span>
        </template>
        <b-card>
          <EmpresaCadastro />
        </b-card>
      </b-tab>
      <b-tab lazy>
        <template #title>
          <span class="font-weight-bold">Motivos</span>
        </template>
        <b-card>
          <MotivoCadastro />
        </b-card>
      </b-tab>
      <b-tab lazy>
        <template #title>
          <span class="font-weight-bold">Metas</span>
        </template>
        <b-card>
          <MetasCredEExec />
        </b-card>
      </b-tab>
      <b-tab lazy>
        <template #title>
          <span class="font-weight-bold">Classificação</span>
        </template>
        <b-card>
          <LocalizacaoCadastro />
        </b-card>
      </b-tab>
      <b-tab lazy>
        <template #title>
          <span class="font-weight-bold">Link PowerBI</span>
        </template>
        <b-card>
          <LinkPowerBI />
        </b-card>
      </b-tab>
      <b-tab lazy>
        <template #title>
          <span class="font-weight-bold">Roteiro</span>
        </template>
        <b-card>
          <Roteiro />
        </b-card>
      </b-tab>
      <b-tab lazy>
        <template #title>
          <span class="font-weight-bold">Perfil do Cliente</span>
        </template>
        <b-card>
          <PerfilCliente />
        </b-card>
      </b-tab>
      <b-tab lazy>
        <template #title>
          <span class="font-weight-bold">Indicadores</span>
        </template>
        <b-card>
          <indicadores />
        </b-card>
      </b-tab>
      <b-tab lazy>
        <template #title>
          <span class="font-weight-bold">Números Guru</span>
        </template>
        <b-card>
          <NumerosGuru />
        </b-card>
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import {
  BTabs,
  BTab,
  BCard,
} from 'bootstrap-vue'
import FuncionarioCadastro from './tabs/FuncionarioCadastro.vue'
import CidadeCadastro from './tabs/CidadeCadastro.vue'
import EmpresaCadastro from './tabs/EmpresaCadastro.vue'
import MotivoCadastro from './tabs/MotivoCadastro.vue'
import MetasCredEExec from './tabs/MetasCredEExec.vue'
import LocalizacaoCadastro from './tabs/LocalizacaoCadastro.vue'
import LinkPowerBI from './tabs/LinkPowerBI.vue'
import Roteiro from './tabs/Roteiro.vue'
import PerfilCliente from './tabs/PerfilCliente.vue'
import Indicadores from './tabs/Indicadores.vue'
import NumerosGuru from './tabs/NumerosGuru.vue'

export default {
  components: {
    FuncionarioCadastro,
    CidadeCadastro,
    EmpresaCadastro,
    MotivoCadastro,
    MetasCredEExec,
    LocalizacaoCadastro,
    LinkPowerBI,
    Roteiro,
    PerfilCliente,
    Indicadores,
    NumerosGuru,
    BTabs,
    BTab,
    BCard,
  },
}
</script>

<template>
  <div>
    <b-button
      v-b-modal.perfilModal
      class="ml-2"
      variant="primary"
    >
      Novo Perfil do Cliente
    </b-button>
    <b-card>
      <b-overlay
        :show="load"
        variant="transparent"
      >
        <b-table
          :items="items"
          :fields="fields"
        >
          <template #cell(acoes)="data">
            <b-row align-h="center">
              <b-col cols="auto">
                <feather-icon
                  icon="ToolIcon"
                  class="cursor-pointer"
                  @click="editPerfil(data.item.id)"
                />
              </b-col>
            </b-row>
          </template>
        </b-table>
      </b-overlay>
    </b-card>
    <b-modal
      id="perfilModal"
      ref="perfilModal"
      :title="perfilID ? 'Editar Perfil' : 'Cadastrar Perfil'"
      :ok-title=" perfilID ? 'Atualizar' : 'Cadastrar'"
      cancel-title="Cancelar"
      cancel-variant="danger"
      no-close-on-backdrop
      @cancel="clearForm"
      @ok="perfilID ? updatePerfil() : submit()"
    >
      <b-row>
        <b-col>
          <label for=""><strong>Nome: </strong></label>
          <b-form-input
            v-model="nome"
            type="text"
          />
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { BCol, BRow, BFormInput, BCard, BTable, BButton, BOverlay } from 'bootstrap-vue'
import axios from '@/../axios-auth'

export default {
  components: {
    BCol,
    BRow,
    BFormInput,
    BCard,
    BTable,
    BButton,
    BOverlay,
  },
  data() {
    return {
      items: [],
      perfilID: null,
      fields: [
        { key: 'nome', label: 'nome', class: 'text-center' },
        { key: 'acoes', label: 'AÇÕES', class: 'text-center' },
      ],
      nome: '',
      load: true,
    }
  },
  created() {
    this.getPerfis()
  },
  methods: {
    clearForm() {
      this.nome = ''
      this.perfilID = null
    },
    submit() {
      this.load = true
      const body = {
        nome: this.nome,
      }
      axios.post('api/v1/perfil_cliente/store', body, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(() => {
          this.clearForm()
          this.getPerfis()
          this.$swal({
            title: 'Novo perfil cadastrado com sucesso!',
            icon: 'success',
            showConfirmButton: false,
          })
        })
        .catch(error => {
          if (error.response) {
            this.clearForm()
            this.getPerfis()
            this.$swal({
              title: 'Ops, erro ao cadastrar um perfil =(',
              icon: 'danger',
              text: error.response.data.mensagem,
              showConfirmButton: false,
              timer: 1500,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
        })
    },
    getPerfis() {
      this.items = []
      axios.get('api/v1/perfil_cliente/listar/perfis', {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(res => {
          this.load = false
          res.data.dados.map(item => {
            this.items.unshift(item)
          })
        })
    },
    editPerfil(id) {
      this.load = true
      this.perfilID = id
      axios.get(`api/v1/perfil_cliente/edit/${id}`, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(res => {
          this.load = false
          this.nome = res.data.dados.nome
          this.$refs.perfilModal.show()
        })
        .catch(error => {
          this.load = false
          if (error.response) {
            this.clearForm()
            this.$swal({
              title: 'Ops, algo deu errado =(',
              text: error.response.data.mensagem,
              icon: 'danger',
              showConfirmButton: false,
              timer: 1500,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
        })
    },
    updatePerfil() {
      this.load = true
      const body = {
        nome: this.nome,
      }
      axios.put(`api/v1/perfil_cliente/update/${this.perfilID}`, body, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(() => {
          this.clearForm()
          this.getPerfis()
          this.$swal({
            title: 'Perfil atualizado com sucesso!',
            icon: 'success',
            timer: 1500,
            showConfirmButton: false,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
        .catch(error => {
          if (error.response) {
            this.clearForm()
            this.getPerfis()
            this.$swal({
              title: 'Ops, algo não foi possível atualizar =(',
              text: error.response.data.mensagem,
              icon: 'danger',
              timer: 1500,
              showConfirmButton: false,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
        })
    },
  },
}
</script>

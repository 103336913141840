<template>
  <div>
    <b-button
      v-b-modal.numeroGuru
      class="ml-2"
      variant="primary"
      @click="clearForm"
    >
      Novo Número
    </b-button>
    <b-card>
      <b-overlay
        :show="load"
        variant="transparent"
      >
        <b-table
          :items="items"
          :fields="fields"
        >
          <template #cell(acoes)="data">
            <b-row align-h="center">
              <b-col cols="auto">
                <feather-icon
                  icon="ToolIcon"
                  class="cursor-pointer"
                  @click="editNumeroGuru(data.item.id)"
                />
              </b-col>
              <b-col cols="auto">
                <feather-icon
                  icon="XIcon"
                  class="iconeX cursor-pointer"
                  @click="removeNumeroGuru(data.item.id)"
                />
              </b-col>
            </b-row>
          </template>
        </b-table>
      </b-overlay>
    </b-card>
    <b-modal
      id="numeroGuru"
      ref="numeroGuruModal"
      :title="numeroGuruID ? 'Editar Número Guru' : 'Cadastrar Número Guru'"
      :ok-title=" numeroGuruID ? 'Atualizar' : 'Cadastrar'"
      cancel-title="Cancelar"
      cancel-variant="danger"
      no-close-on-backdrop
      @ok="numeroGuruID ? updateNumeroGuru() : submit()"
    >
      <b-row>
        <b-col>
          <label><strong>Key: </strong></label>
          <b-form-input
            v-model="numeroGuru.key"
            type="text"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label for=""><strong>Account ID: </strong></label>
          <b-form-input
            v-model="numeroGuru.account_id"
            type="text"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label><strong>Phone ID: </strong></label>
          <b-form-input
            v-model="numeroGuru.phone_id"
            type="text"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label class="mt-1 mr-1"><strong>Action:</strong></label>
          <b-form-input
            v-model="numeroGuru.action"
            type="text"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label><strong>Dialog ID: </strong></label>
          <b-form-input
            v-model="numeroGuru.dialog_id"
            type="text"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label class="mt-1 mr-1"><strong>Action Dialog:</strong></label>
          <b-form-input
            v-model="numeroGuru.action_dialog"
            type="text"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label class="mt-1 mr-1"><strong>Tipo Número:</strong></label>
          <v-select
            v-model="numeroGuru.tipo_numero"
            :options="optionsTipoNumeros"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label class="mt-1 mr-1"><strong>Dialog Delegar ID:</strong></label>
          <b-form-input
            v-model="numeroGuru.dialog_delegar_id"
            type="text"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label class="mt-1 mr-1"><strong>Dialog Encerrar ID:</strong></label>
          <b-form-input
            v-model="numeroGuru.dialog_encerrar_id"
            type="text"
          />
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BFormInput,
  BCard,
  BTable,
  BButton,
  BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import axios from '@/../axios-auth'

export default {
  components: {
    BCol,
    BRow,
    BFormInput,
    BCard,
    BTable,
    BButton,
    BOverlay,
    vSelect,
  },
  data() {
    return {
      items: [],
      numeroGuruID: null,
      fields: [
        { key: 'tipo_numero', label: 'TIPO', class: 'text-center' },
        { key: 'phone_id', label: 'NÚMERO GURU', class: 'text-center' },
        { key: 'acoes', label: 'AÇÕES', class: 'text-center' },
      ],
      numeroGuru: {
        key: '',
        account_id: '',
        phone_id: '',
        action: '',
        dialog_id: '',
        action_dialog: '',
        tipo_numero: '',
        dialog_delegar_id: '',
        dialog_encerrar_id: '',
      },
      optionsTipoNumeros: [
        'ATIVO',
        'RECEPTIVO',
      ],
      load: true,
    }
  },
  created() {
    this.getNumerosGuru()
  },
  methods: {
    clearForm() {
      this.numeroGuru.key = ''
      this.numeroGuru.account_id = ''
      this.numeroGuru.phone_id = ''
      this.numeroGuru.action = ''
      this.numeroGuru.dialog_id = ''
      this.numeroGuru.action_dialog = ''
      this.numeroGuru.tipo_numero = ''
      this.numeroGuruID = null
      this.numeroGuru.dialog_delegar_id = ''
      this.numeroGuru.dialog_encerrar_id = ''
    },
    submit() {
      this.load = true
      axios.post('api/v1/chatguru/store', this.numeroGuru)
        .then(() => {
          this.clearForm()
          this.getNumerosGuru()
          this.$swal({
            title: 'Novo Número cadastrado com sucesso!',
            icon: 'success',
            showConfirmButton: false,
          })
        })
        .catch(error => {
          if (error.response) {
            this.clearForm()
            this.getNumerosGuru()
            this.$swal({
              title: 'Ops, erro ao cadastrar um novo número =(',
              icon: 'error',
              text: error.response.data.mensagem,
              showConfirmButton: false,
              timer: 1500,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
        })
    },
    getNumerosGuru() {
      this.items = []
      axios.get('api/v1/chatguru')
        .then(res => {
          this.load = false
          res.data.dados.map(item => {
            this.items.unshift(item)
          })
        })
    },
    editNumeroGuru(id) {
      this.load = true
      this.numeroGuruID = id
      axios.get(`api/v1/chatguru/edit/${id}`)
        .then(res => {
          this.load = false
          this.numeroGuru = res.data.dados
          this.$refs.numeroGuruModal.show()
        })
        .catch(error => {
          this.load = false
          if (error.response) {
            this.clearForm()
            this.$swal({
              title: 'Ops, algo deu errado =(',
              text: error.response.data.mensagem,
              icon: 'error',
              showConfirmButton: false,
              timer: 1500,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
        })
    },
    updateNumeroGuru() {
      this.load = true
      axios.put(`api/v1/chatguru/update/${this.numeroGuruID}`, this.numeroGuru, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(() => {
          this.clearForm()
          this.getNumerosGuru()
          this.$swal({
            title: 'Número atualizado com sucesso!',
            icon: 'success',
            timer: 1500,
            showConfirmButton: false,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
        .catch(error => {
          if (error.response) {
            this.clearForm()
            this.getNumerosGuru()
            this.$swal({
              title: 'Ops, algo não foi possível atualizar =(',
              text: error.response.data.mensagem,
              icon: 'error',
              timer: 1500,
              showConfirmButton: false,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
        })
    },
    removeNumeroGuru(id) {
      this.$swal({
        title: 'Deseja excluir esse número?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, excluir!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios.delete(`api/v1/chatguru/destroy/${id}`)
            .then(() => {
              this.clearForm()
              this.getNumerosGuru()
              this.$swal({
                title: 'Número deletado com sucesso!',
                icon: 'success',
                timer: 1500,
                showConfirmButton: false,
              })
            })
            .catch(() => {
              this.clearForm()
              this.getNumerosGuru()
              this.$swal({
                title: 'Ops, algo deu errado',
                icon: 'error',
                timer: 1500,
                showConfirmButton: false,
              })
            })
        }
      })
    },
  },
}
</script>

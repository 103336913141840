7<template>
  <div>
    <b-button
      v-b-modal.cadastro-localizacao
      variant="primary"
      class="ml-1"
      @click="limparCampo"
    >
      <feather-icon
        icon="PlusIcon"
      />
      Nova Classificação
    </b-button>
    <b-modal
      id="cadastro-localizacao"
      title="Cadastrar Localização"
      ok-title="Cadastrar"
      cancel-title="Cancelar"
      @ok="cadastrarLocalizacao"
    >
      <b-form-group
        label="Nome"
      >
        <b-form-input
          v-model="cad.nome"
        />
      </b-form-group>
    </b-modal>
    <b-card>
      <b-row>
        <b-col>
          <b-row align-h="between">
            <b-col cols="1">
              <b-form-group>
                <b-form-select
                  v-model="perPage"
                  :options="[10, 15, 20]"
                  size="sm"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="8"
            >
              <b-form-group>
                <b-form-input
                  v-model="filter"
                  placeholder="Pequisar"
                  type="search"
                  size="sm"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12">
          <b-table
            responsive
            bordered
            striped
            hover
            :filter="filter"
            :fields="fieldsLocalizacao"
            :items="itemsLocalizacao"
            :per-page="perPage"
            :current-page="currentPage"
            @row-clicked="rowClicked"
            @filtered="onFiltered"
          />
        </b-col>
        <b-col cols="12">
          <b-pagination
            v-model="currentPage"
            :per-page="perPage"
            :total-rows="totalRows"
            align="center"
            size="sm"
          />
        </b-col>
      </b-row>
    </b-card>
    <b-modal
      id="editModal"
      ref="editModal"
      title="Detalhes da Localização"
      hide-footer
    >
      <b-row>
        <b-col
          class="mb-2"
          cols="12"
        >
          Nome:
          <b-form-input
            v-model="localizacao.nome"
            :disabled="editar"
          />
        </b-col>
        <b-col
          cols="12"
          class="mb-2"
        >
          Cor:
          <b-form-input
            v-model="localizacao.cor"
            :disabled="editar"
          />
        </b-col>
        <b-col>
          <b-form-checkbox
            v-model="localizacao.status"
            :disabled="editar"
            switch
          />
        </b-col>
      </b-row>
      <hr>
      <b-row>
        <b-col>
          <b-button
            v-show="editar"
            variant="outline-primary"
            @click="edicao"
          >
            <feather-icon
              icon="EditIcon"
            />
            Editar
          </b-button>
        </b-col>
        <b-col>
          <b-button
            variant="outline-danger"
            @click="deleteLoc"
          >
            <feather-icon
              icon="TrashIcon"
            />
            Excluir
          </b-button>
        </b-col>
        <b-col>
          <b-button
            v-show="!editar"
            variant="outline-secondary"
            @click="$refs.editModal.hide()"
          >
            <feather-icon
              icon="EditIcon"
            />
            Cancelar
          </b-button>
        </b-col>
        <b-col>
          <b-button
            variant="primary"
            @click="saveLoc(localizacao.id)"
          >
            <feather-icon
              icon="EditIcon"
            />
            Salvar
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
import {
  BButton,
  BModal,
  VBModal,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormCheckbox,
  BCard,
  BRow,
  BCol,
  BTable,
  BPagination,
} from 'bootstrap-vue'
import axios from '@/../axios-auth'

export default {
  components: {
    BButton,
    BModal,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BTable,
    BPagination,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      editar: true,
      filter: '',
      cad: {
        nome: null,
        status: true,
      },
      totalRows: 1,
      perPage: 10,
      currentPage: 1,
      fieldsLocalizacao: [],
      itemsLocalizacao: [],
      localizacao: [],
      idLocalizacao: null,
    }
  },
  async created() {
    this.listLoc()
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    limparCampo() {
      this.cad = {
        nome: null,
        status: true,
      }
    },
    edicao() {
      this.editar = false
    },
    rowClicked(item) {
      this.idLocalizacao = false
      this.getLocalizacao(item.id)
    },

    async listLoc() {
      this.totalRows = null
      this.itemsLocalizacao = []
      await axios.get('api/v1/status_localizacao', {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(async res => {
        await res.data.dados.dados.map(dt => {
          this.itemsLocalizacao.push({
            id: dt.id,
            nome: dt.nome,
          })
        })
        this.totalRows = this.itemsLocalizacao.length
      })
    },

    async getLocalizacao(id) {
      this.localizacao = []
      await axios.get(`api/v1/status_localizacao/edit/${id}`, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(async res => {
        this.localizacao = {
          id: res.data.dados.status_localizacao.id,
          nome: res.data.dados.status_localizacao.nome,
          cor: res.data.dados.status_localizacao.cor,
          status: res.data.dados.status_localizacao.status,
        }
      })
      this.editar = false
      this.$refs.editModal.show()
    },

    async cadastrarLocalizacao() {
      await axios.post('api/v1/status_localizacao/store', this.cad, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(async res => {
        if (await res.data.dados) {
          this.listLoc()
          this.$refs.editModal.hide()
          this.$swal({
            icon: 'success',
            title: 'Cadastrado!',
            text: 'Localização cadastrada com sucesso!',
            timer: 1500,
            showConfirmButton: false,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      }).catch(async error => {
        this.$swal({
          title: 'Erro ao Cadastrar Localização !',
          text: error.response.data.mensagem,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
    },

    async deleteLoc() {
      this.$swal({
        title: 'Tem certeza?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim quero deletar!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios.delete(`api/v1/status_localizacao/delete/${this.idLocalizacao}`, {
            headers: {
              Authorization: 'Bearer '.concat(localStorage.getItem('token')),
              accept: 'application/json',
            },
          }).then(() => {
            this.$swal({
              icon: 'success',
              title: 'Cadastrado!',
              text: 'Localização excluida com sucesso!',
              timer: 1500,
              showConfirmButton: false,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }).catch(error => {
            this.$swal({
              title: 'Erro ao Excluir Localização !',
              text: error.response.data.mensagem,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          })
          this.$refs.editModal.hide()
          this.listLoc()
        }
      })
    },
    async saveLoc(id) {
      axios.put(`api/v1/status_localizacao/update/${id}`, this.cad, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(() => {
        this.listLoc()
        this.$refs.editModal.hide()
        this.$swal({
          title: 'Sucesso!',
          icon: 'success',
          text: 'Localização alterada com sucesso!',
          timer: 1500,
          showConfirmButton: false,
        })
      }).catch(error => {
        this.$swal({
          title: 'Errro ao alterar localização!',
          text: error.response.data.mensagem,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
    },
  },
}
</script>

<template>
  <div>
    <div>
      <b-button
        v-b-modal.metas-modal
        variant="primary"
        @click="() => {
          if ($refs.fieldValue === 'Metas Executivos') {
            tabGroup = 'Metas Executivos';
          } else if ($refs.fieldValue === 'Metas Credores') {
            tabGroup = 'Metas Credores';
          }
          limparCampo();
        }"
      >
        <feather-icon icon="PlusIcon" />
        Meta
      </b-button>
      <b-button
        v-b-modal.metasEmLote
        class="ml-2"
        variant="primary"
      >
        <feather-icon icon="PlusIcon" />
        Cadastrar Meta em Lote
      </b-button>
    </div>
    <b-modal
      id="metas-modal"
      ref="metas-modal"
      title="Cadastrar Meta"
      ok-title="Cadastrar"
      cancel-title="Cancelar"
      :active-tab-component="activeTabComponent"
      @ok="cadastrarMeta"
    >
      <div v-if="tipo === 'executivo'">
        <b-form-group
          label="Executivo"
          class="mt-2"
        >
          <v-select
            v-model="metaExecutivos.executivo_id"
            :options="executivos"
          />
        </b-form-group>
        <b-form-group
          label="Ano"
          class="mt-2"
        >
          <b-form-input
            v-model="metaExecutivos.ano"
            type="number"
          />
        </b-form-group>
        <b-form-group
          label="Mês"
          class="mt-2"
        >
          <b-form-input
            v-model="metaExecutivos.mes"
            type="number"
          />
        </b-form-group>
        <b-form-group
          label="Meta"
          class="mt-2"
        >
          <b-form-input
            v-model="metaExecutivos.valor"
            type="number"
          />
        </b-form-group>
      </div>
      <div v-else>
        <b-form-group
          label="Credor"
          class="mt-2"
        >
          <v-select
            v-model="metaCredores.credor_id"
            :options="credores"
          />
        </b-form-group>
        <b-form-group
          label="Ano"
          class="mt-2"
        >
          <b-form-input
            v-model="metaCredores.ano"
          />
        </b-form-group>
        <b-form-group
          label="Mês"
          class="mt-2"
        >
          <b-form-input
            v-model="metaCredores.mes"
          />
        </b-form-group>
        <b-form-group
          label="Meta"
          class="mt-2"
        >
          <b-form-input
            v-model="metaCredores.valor"
          />
        </b-form-group>
      </div>

    </b-modal>
    <b-overlay :show="load">
      <b-card
        class="mt-2"
      >
        <b-tabs
          ref="tabs"
          v-model="tabIndex1"
          justified
          pills
        >
          <b-tab
            :value="fieldValue"
            @click="tipoMeta('executivo')"
          >
            <template #title>
              <span class="font-weight-bold">Metas Executivos</span>
            </template>
            <b-row>
              <b-col>
                <b-row align-h="between">
                  <b-col
                    cols="1"
                  >
                    <b-form-select
                      v-model="perPage"
                      :options="[10, 15, 20]"
                      size="sm"
                    />
                  </b-col>
                  <b-col
                    lg="8"
                  >
                    <b-form-input
                      v-model="filter"
                      placeholder="Pesquisar"
                      size="sm"
                    />
                  </b-col>
                </b-row>
              </b-col>
              <b-col
                cols="12"
                class="mt-2"
              >
                <b-table
                  responsive
                  bordered
                  striped
                  hover
                  :filter="filter"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :fields="fieldsMetasExecutivos"
                  :items="itemsMetasExec"
                  @filtered="onFiltered"
                >
                  <template #cell(acoes)="data">
                    <b-row align-h="center">
                      <b-col cols="auto">
                        <feather-icon
                          icon="ToolIcon"
                          class="cursor-pointer"
                          @click="getMetas(data.item.id)"
                        />
                      </b-col>
                      <b-col cols="auto">
                        <feather-icon
                          icon="XIcon"
                          class="iconeX cursor-pointer"
                          @click="deleteMeta(data.item.id)"
                        />
                      </b-col>
                    </b-row>
                  </template>
                </b-table>
              </b-col>
              <b-col>
                <b-pagination
                  v-model="currentPage"
                  :per-page="perPage"
                  :total-rows="totalRows"
                  align="center"
                  size="sm"
                />
              </b-col>
            </b-row>
          </b-tab>
          <b-tab
            field-value="Metas Credores"
            :value="fieldValue"
            active
            @click="tipoMeta('credores')"
          >
            <template #title>
              <span class="font-weight-bold">Metas Credores</span>
            </template>
            <b-row>
              <b-col>
                <b-row align-h="between">
                  <b-col
                    cols="1"
                  >
                    <b-form-select
                      v-model="perPage1"
                      :options="[10, 15, 20]"
                      size="sm"
                    />
                  </b-col>
                  <b-col
                    lg="8"
                  >
                    <b-form-input
                      v-model="filter1"
                      placeholder="Pesquisar"
                      size="sm"
                    />
                  </b-col>
                </b-row>
              </b-col>
              <b-col
                cols="12"
                class="mt-2"
              >
                <b-table
                  responsive
                  bordered
                  striped
                  hover
                  :filter="filter1"
                  :per-page="perPage1"
                  :current-page="currentPage1"
                  :fields="fieldsMetasCredores"
                  :items="itemsMetasCred"
                  @filtered="filderedMetasCred"
                >
                  <template #cell(acoes)="data">
                    <b-row align-h="center">
                      <b-col cols="auto">
                        <feather-icon
                          icon="ToolIcon"
                          class="cursor-pointer"
                          @click="getMetas(data.item.id)"
                        />
                      </b-col>
                      <b-col cols="auto">
                        <feather-icon
                          icon="XIcon"
                          class="iconeX cursor-pointer"
                          @click="deleteMeta(data.item.id)"
                        />
                      </b-col>
                    </b-row>
                  </template>
                </b-table>
              </b-col>
              <b-col>
                <b-pagination
                  v-model="currentPage1"
                  :per-page="perPage1"
                  :total-rows="totalRows1"
                  align="center"
                  size="sm"
                />
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </b-card>
    </b-overlay>
    <b-modal
      id="editModal"
      ref="editModal"
      title="Detalhes Meta"
      hide-footer
    >
      <b-row>
        <b-col
          cols="12"
          class="mb-2"
        />
        <b-col
          cols="12"
          class="mb-2"
        >
          Ano:
          <b-form-input
            v-model="edit.ano"
            :disabled="edicao"
          />
        </b-col>
        <b-col
          cols="12"
          class="mb-2"
        >
          Mês:
          <b-form-input
            v-model="edit.mes"
            :disabled="edicao"
          />
        </b-col>
        <b-col
          cols="12"
          class="mb-2"
        >
          Meta:
          <b-form-input
            v-model="edit.valor"
            :disabled="edicao"
          />
        </b-col>
      </b-row>
      <hr>
      <b-row>
        <b-col>
          <b-button
            v-show="edicao"
            variant="outline-primary"
            @click="editar"
          >
            <feather-icon
              icon="EditIcon"
            />
            Editar
          </b-button>
        </b-col>
        <b-col>
          <b-button
            variant="outline-danger"
            @click="deleteMeta"
          >
            <feather-icon
              icon="TrashIcon"
            />
            Excluir
          </b-button>
        </b-col>
        <b-col>
          <b-button
            v-show="!edicao"
            variant="outline-secondary"
            @click="$refs.editModal.hide()"
          >
            <feather-icon
              icon="XIcon"
            />
            Cancelar
          </b-button>
        </b-col>
        <b-col>
          <b-button
            variant="primary"
            @click="saveMeta"
          >
            <feather-icon
              icon="SaveIcon"
            />
            Salvar
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      id="metasEmLote"
      ref="metasEmLote"
      ok-title="Cadastrar em lote"
      cancel-title="Cancelar"
      cancel-variant="danger"
      :ok-disabled="file === null || tipoModelo === '' "
      @ok="importarMetaEmLote"
    >
      <form
        ref="form"
      >
        <b-form-group
          label="Selecione o arquivo"
          label-for="name"
        >
          <b-form-file
            v-model="file"
            placeholder=""
            drop-placeholder="Arquivo"
          />
        </b-form-group>
        <label>Tipo Modelo</label>
        <v-select
          v-model="tipoModelo"
          :options="tiposModelo"
        />
      </form>
    </b-modal>
  </div>
</template>
<script>
import {
  BCol,
  BRow,
  BCard,
  BFormInput,
  BFormSelect,
  BFormGroup,
  BTable,
  BPagination,
  BButton,
  BModal,
  VBModal,
  BTabs,
  BTab,
  BFormFile,
  BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import axios from '@/../axios-auth'

export default {
  components: {
    BCol,
    BRow,
    BCard,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BTable,
    BPagination,
    BButton,
    BModal,
    BTabs,
    BTab,
    vSelect,
    BFormFile,
    BOverlay,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      activeTabComponent: 'tab1',
      tabIndex1: null,
      tabIndex: null,
      tipo: 'credores',
      fieldValue: 'Metas Executivos',
      form: {
        credores: [],
      },
      credores: [],
      executivos: [],
      edicao: true,
      edit: [],

      metaExecutivos: {
        executivo_id: [],
        ano: '',
        mes: '',
        valor: '',
      },

      metaCredores: {
        credor_id: [],
        ano: '',
        mes: '',
        valor: '',
      },
      idEditMeta: null,
      filter: null,
      perPage: 10,
      totalRows: 1,
      currentPage: 1,
      itemsMetasExec: [],
      fieldsMetasExecutivos: [{
        key: 'id', class: 'text-center',
      },
      {
        key: 'executivoNome', label: 'Executivo', class: 'text-center',
      },
      {
        key: 'ano', class: 'text-center',
      },
      {
        key: 'mes', label: 'Mês', class: 'text-center',
      },
      {
        key: 'meta', class: 'text-center',
      },
      {
        key: 'acoes', class: 'text-center',
      }],

      filter1: null,
      perPage1: 10,
      totalRows1: 1,
      currentPage1: 1,
      itemsMetasCred: [],
      fieldsMetasCredores: [{
        key: 'id', class: 'text-center',
      },
      {
        key: 'credorNome', label: 'credor', class: 'text-center',
      },
      {
        key: 'ano', class: 'text-center',
      },
      {
        key: 'mes', label: 'Mês', class: 'text-center',
      },
      {
        key: 'meta', class: 'text-center',
      },
      {
        key: 'acoes', class: 'text-center',
      }],

      filter2: null,
      perPage2: 10,
      totalRows2: 1,
      currentPage2: 1,
      tiposModelo: [
        'EXECUTIVO',
        'CREDOR',
      ],
      file: null,
      tipoModelo: '',
      load: false,
    }
  },
  created() {
    this.listarMetasExecutivos()
    this.listarMetasCredores()
    this.getCredores()
    this.getExecutivos()
  },
  methods: {
    tipoMeta(tipo) {
      this.tipo = tipo
    },
    async getCredores() {
      await axios.get('api/v1/credores/listar/false', {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(res => {
        res.data.dados.map(item => {
          this.credores.push({
            label: item.nome,
            id: item.id,
          })
        })
      })
    },
    async getExecutivos() {
      await axios.get('api/v1/executivo/', {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          Accept: 'application/json',
        },
      }).then(res => {
        res.data.map(executivo => {
          this.executivos.push(
            {
              label: executivo.nome,
              id: executivo.id,
            },
          )
        })
      })
    },
    editar() {
      this.edicao = false
    },

    limparCampo() {
      if (this.fieldValue === 'Metas Credores') {
        this.tabIndex = 'Metas Credores'
      } else if (this.fieldValue === 'Metas Executivos') {
        this.tabIndex = 'Metas Executivos'
      }

      this.metaCredores = {
        credor_id: null,
        ano: null,
        mes: null,
        valor: null,
      }
      this.metaExecutivos = {
        executivo_id: null,
        ano: null,
        mes: null,
        valor: null,
      }
    },
    moedaBR(valor) {
      const numero = parseFloat(valor)
      const moeda = numero.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      })
      return moeda
    },
    // LISTAGEM
    async listarMetasExecutivos() {
      this.totalRows = null
      this.itemsMetasExec = []
      await axios.get('api/v1/meta_executivo', {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(async res => {
        // eslint-disable-next-line array-callback-return
        await res.data.map(meta => {
          this.itemsMetasExec.unshift({
            idExecutivo: meta.executivo_id,
            executivoNome: meta.executivo_nome,
            ano: meta.ano,
            mes: meta.mes,
            id: meta.id,
            meta: this.moedaBR(meta.valor),
          })
        })
        this.totalRows = this.itemsMetasExec.length
      })
    },

    async listarMetasCredores() {
      this.totalRows1 = null
      this.itemsMetasCred = []
      await axios.get('api/v1/meta_credor', {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(async res => {
        // eslint-disable-next-line array-callback-return
        await res.data.map(meta => {
          this.itemsMetasCred.unshift({
            idCredor: meta.credor_id,
            credorNome: meta.credor_nome,
            ano: meta.ano,
            mes: meta.mes,
            id: meta.id,
            meta: this.moedaBR(meta.valor),
          })
        })
        this.totalRows1 = this.itemsMetasCred.length
      })
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    filderedMetasCred(filteredItems) {
      this.totalRows1 = filteredItems.length
      this.currentPage1 = 1
    },

    async getMetas(id) {
      switch (this.tabIndex1) {
        default:
          break
        case 0:
          this.edit = []
          this.edicao = true
          this.idEditMeta = id
          await axios.get(`api/v1/meta_executivo/edit/${id}`, {
            headers: {
              Authorization: 'Bearer '.concat(localStorage.getItem('token')),
              accept: 'application/json',
            },
          }).then(res => {
            this.edit = {
              executivo: res.data.dados.executivo_id,
              ano: res.data.dados.ano,
              mes: res.data.dados.mes,
              valor: res.data.dados.valor,
            }
            this.$refs.editModal.show()
          })
          break

        case 1:
          this.edit = []
          this.edicao = true
          this.idEditMeta = id
          await axios.get(`api/v1/meta_credor/edit/${id}`, {
            headers: {
              Authorization: 'Bearer '.concat(localStorage.getItem('token')),
              accept: 'application/json',
            },
          }).then(res => {
            this.edit = {
              credor: res.data.dados.credor_id,
              ano: res.data.dados.ano,
              mes: res.data.dados.mes,
              valor: res.data.dados.valor,
            }
            this.$refs.editModal.show()
          })
          break
      }
    },

    cadastrarMeta() {
      switch (this.tabIndex1) {
        case 0:
          this.metaExecutivos.executivo_id = this.metaExecutivos.executivo_id.id
          axios.post('api/v1/meta_executivo/store', this.metaExecutivos, {
            headers: {
              Authorization: 'Bearer '.concat(localStorage.getItem('token')),
              accept: 'application/json',
            },
          }).then(() => {
            this.listarMetasExecutivos()
            this.$swal({
              icon: 'success',
              title: 'SUCESSO!',
              text: 'Meta cadastrado com sucesso!',
              timer: 1500,
              showConfirmButton: false,
            })
          }).catch(async error => {
            if (await error.response) {
              this.$swal({
                title: 'Erro ao Cadastrar Meta !',
                text: error.response.data.mensagem,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            }
          })
          break
        case 1:
          this.metaCredores.credor_id = this.metaCredores.credor_id.id
          axios.post('api/v1/meta_credor/store', this.metaCredores, {
            headers: {
              Authorization: 'Bearer '.concat(localStorage.getItem('token')),
              accept: 'application/json',
            },
          }).then(() => {
            this.listarMetasCredores()
            this.$swal({
              icon: 'success',
              title: 'SUCESSO!',
              text: 'Meta cadastrada com sucesso!',
              timer: 1500,
              showConfirmButton: false,
            })
          }).catch(error => {
            if (error.response) {
              this.$swal({
                title: 'Erro ao Cadastrar  Meta !',
                text: error.response.data.mensagem,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            }
          })
          break
        default:
          break
      }
    },

    async saveMeta() {
      switch (this.tabIndex1) {
        default:
          break
        case 0:
          await axios.put(`api/v1/meta_executivo/update/${this.idEditMeta}`, this.edit, {
            headers: {
              Authorization: 'Bearer '.concat(localStorage.getItem('token')),
              accept: 'application/json',
            },
          }).then(() => {
            this.$refs.editModal.hide()
            this.$swal({
              icon: 'success',
              title: 'Sucesso',
              text: 'Alteração salva com sucesso"!',
              timer: 1500,
              showConfirmButton: false,
            })
          }).catch(error => {
            if (error.response) {
              this.$swal({
                title: 'Erro ao Alterar a Meta !',
                text: error.response.data.mensagem,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            }
          })
          await this.listarMetasExecutivos()
          break
        case 1:
          await axios.put(`api/v1/meta_credor/update/${this.idEditMeta}`, this.edit, {
            headers: {
              Authorization: 'Bearer '.concat(localStorage.getItem('token')),
              accept: 'application/json',
            },
          }).then(() => {
            this.$refs.editModal.hide()
            this.$swal({
              icon: 'success',
              title: 'Sucesso',
              text: 'Alteração salva com sucesso!',
              timer: 1500,
              showConfirmButton: false,
            })
          }).catch(async error => {
            if (await error.response) {
              this.$swal({
                title: 'Erro ao Alterar Meta!',
                text: error.response.data.mensagem,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            }
          })
          await this.listarMetasCredores()
          break
      }
    },

    async deleteMeta(id) {
      this.$swal({
        title: 'Tem certeza?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim quero deletar!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          switch (this.tabIndex1) {
            default:
              break
            case 0:
              await axios.delete(`api/v1/meta_executivo/delete/${id}`, {
                headers: {
                  Authorization: 'Bearer '.concat(localStorage.getItem('token')),
                  accept: 'application/json',
                },
              }).then(() => {
                this.$swal({
                  icon: 'success',
                  title: 'Excluido',
                  text: 'Meta excluido com sucesso"!',
                  timer: 1500,
                  showConfirmButton: false,
                })
              }).catch(async error => {
                if (await error.response) {
                  this.$swal({
                    title: 'Erro ao Excluir Meta !',
                    text: error.response.data.mensagem,
                    icon: 'error',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                }
              })
              await this.$refs.editModal.hide()
              await this.listarMetasExecutivos()
              break
            case 1:
              await axios.delete(`api/v1/meta_credor/delete/${id}`, {
                headers: {
                  Authorization: 'Bearer '.concat(localStorage.getItem('token')),
                  accept: 'application/json',
                },
              }).then(() => {
                this.$swal({
                  icon: 'success',
                  title: 'Excluido',
                  text: 'Meta excluido com sucesso!',
                  timer: 1500,
                  showConfirmButton: false,
                })
              }).catch(async error => {
                if (await error.response) {
                  this.$swal({
                    title: 'Erro ao Excluir Meta!',
                    text: error.response.data.mensagem,
                    icon: 'error',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                }
              })
              await this.$refs.editModal.hide()
              await this.listarMetasCredores()
              break
          }
        }
      })
    },
    importarMetaEmLote() {
      this.load = true
      const formData = new FormData()
      formData.append('planilha', this.file)
      formData.append('tipo_modelo', this.tipoModelo)
      axios.post('api/v1/meta_executivo/lote', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(() => {
          this.load = false
          this.file = null
          this.tipoModelo = ''
          this.$swal({
            title: 'Metas cadastradas com sucesso!',
            icon: 'success',
            timer: 1500,
            showConfirmButton: false,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          this.listarMetasExecutivos()
          this.listarMetasCredores()
        })
        .catch(error => {
          this.load = false
          this.file = null
          this.tipoModelo = ''
          if (error.response) {
            this.$swal({
              title: 'Erro!',
              text: error.response.data.mensagem,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }
          this.listarMetasExecutivos()
          this.listarMetasCredores()
        })
    },
  },
}
</script>

<template>
  <div>
    <b-button
      v-b-modal.linkPowerBI
      class="ml-2"
      variant="primary"
    >
      Cadastrar Link
    </b-button>
    <b-card>
      <b-table
        :items="items"
        :fields="fields"
      >
        <template #cell(acoes)="data">
          <b-row align-h="center">
            <b-col cols="auto">
              <feather-icon
                icon="ToolIcon"
                class="cursor-pointer"
                @click="editLink(data.item.id)"
              />
            </b-col>
            <b-col cols="auto">
              <feather-icon
                icon="XIcon"
                class="iconeX cursor-pointer"
                @click="removeLink(data.item.id)"
              />
            </b-col>
          </b-row>
        </template>
      </b-table>
    </b-card>
    <b-modal
      id="linkPowerBI"
      ref="linkPowerBI"
      :title="linkID ? 'Editar Link' : 'Cadastrar link'"
      :ok-title=" linkID ? 'Atualizar' : 'Cadastrar'"
      cancel-title="Cancelar"
      cancel-variant="danger"
      no-close-on-backdrop
      @cancel="clearForm"
      @ok="linkID ? updateLink() : submit()"
    >
      <b-row>
        <b-col>
          <label for=""><strong>Título: </strong></label>
          <b-form-input
            v-model="form.titulo"
            type="text"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label for=""><strong>Link: </strong></label>
          <b-form-input
            v-model="form.link"
            type="text"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label for=""><strong>Selecione as funções que podem visualizar o link : </strong></label>
          <v-select
            v-model="form.departamentos"
            :options="optionsFuncoes"
            :close-on-select="false"
            multiple
          />
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { BCol, BRow, BFormInput, BCard, BTable, BButton } from 'bootstrap-vue'
import vSelect from 'vue-select'
import axios from '@/../axios-auth'

export default {
  components: {
    BCol,
    BRow,
    BFormInput,
    BCard,
    BTable,
    BButton,
    vSelect,
  },
  data() {
    return {
      items: [],
      linkID: null,
      fields: [
        { key: 'titulo', label: 'TITULO', class: 'text-center' },
        { key: 'acoes', label: 'AÇÕES', class: 'text-center' },
      ],
      form: {
        titulo: '',
        link: '',
        departamentos: [],
      },
      optionsFuncoes: [],
    }
  },
  created() {
    this.getLinks()
    this.listaFuncoes()
  },
  methods: {
    clearForm() {
      this.form.titulo = ''
      this.form.link = ''
      this.linkID = null
      this.form.departamentos = []
    },
    submit() {
      axios.post('api/v1/link_powerbi/store', this.form, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(() => {
          this.clearForm()
          this.getLinks()
          this.$swal({
            title: 'Link Cadastrado com sucesso!',
            icon: 'success',
            timer: 1500,
            showConfirmButton: false,
          })
        })
        .catch(() => {
          this.clearForm()
          this.getLinks()
          this.$swal({
            title: 'Ops, erro ao cadastrar link =(',
            icon: 'danger',
            timer: 1500,
            showConfirmButton: false,
          })
        })
    },
    getLinks() {
      this.items = []
      axios.get('api/v1/link_powerbi/', {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(res => {
          res.data.dados.map(item => {
            this.items.unshift(item)
          })
        })
    },
    editLink(id) {
      this.linkID = id
      axios.get(`api/v1/link_powerbi/edit/${id}`, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(res => {
          this.form = {
            titulo: res.data.dados.titulo,
            link: res.data.dados.link,
            departamentos: JSON.parse(res.data.dados.departamentos),
          }
          this.$refs.linkPowerBI.show()
        })
        .catch(() => {
          this.clearForm()
          this.$swal({
            title: 'Ops, algo deu errado =(',
            icon: 'danger',
            timer: 1500,
            showConfirmButton: false,
          })
        })
    },
    updateLink() {
      axios.put(`api/v1/link_powerbi/update/${this.linkID}`, this.form, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(() => {
          this.clearForm()
          this.getLinks()
          this.$swal({
            title: 'Link atualizado com sucesso!',
            icon: 'success',
            timer: 1500,
            showConfirmButton: false,
          })
        })
        .catch(() => {
          this.clearForm()
          this.getLinks()
          this.$swal({
            title: 'Ops, algo não foi possível atualizar =(',
            icon: 'danger',
            timer: 1500,
            showConfirmButton: false,
          })
        })
    },
    removeLink(id) {
      this.$swal({
        title: 'Deseja excluir esse link?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, excluir!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios.delete(`api/v1/link_powerbi/delete/${id}`, {
            headers: {
              Authorization: 'Bearer '.concat(localStorage.getItem('token')),
              accept: 'application/json',
            },
          })
            .then(() => {
              this.clearForm()
              this.getLinks()
              this.$swal({
                title: 'Link deletado com sucesso!',
                icon: 'success',
                timer: 1500,
                showConfirmButton: false,
              })
            })
            .catch(() => {
              this.clearForm()
              this.getLinks()
              this.$swal({
                title: 'Ops, algo deu errado',
                icon: 'danger',
                timer: 1500,
                showConfirmButton: false,
              })
            })
        }
      })
    },
    async listaFuncoes() {
      this.optionsFuncoes = []
      await axios.get('api/v1/funcao', {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(res => {
        res.data.dados.map(dt => {
          this.optionsFuncoes.push({
            label: dt.nome,
            value: dt.id,
          })
        })
      })
    },
  },
}
</script>

<style>
.iconeX {
  color: red;
}
</style>

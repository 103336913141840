<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        class="mb-1"
      >
        Nome de usuário:
        <b-form-input
          v-model="executivo.usuario"
        />
      </b-col>
      <b-col
        cols="12"
        class="mb-1"
      >
        Senha:
        <b-form-input
          v-model="executivo.senha"
          type="password"
        />
      </b-col>
      <b-col
        cols="12"
        class="mb-1"
      >
        Ramal:
        <b-form-input
          v-model="executivo.ramal"
        />
      </b-col>
    </b-row>
    <hr>
    <b-row align-h="end">
      <b-col cols="auto">
        <b-button
          v-show="isExecutive"
          variant="warning"
          @click="editExecutivo"
        >
          Editar
        </b-button>

      </b-col>
      <b-col cols="auto">
        <b-button
          variant="primary"
          @click="storeDiscador"
        >
          Criar
        </b-button>
      </b-col>
    </b-row>
    <b-modal
      ref="modalEdit"
      title="Editar Executivo Fastway"
      ok-title="Salvar"
      @ok="editStore"
    >
      <b-row>
        <b-col
          cols="12"
          class="mb-1"
        >
          Nome de usuário:
          <b-form-input
            v-model="executivoEdit.usuario"
          />
        </b-col>
        <b-col
          cols="12"
          class="mb-1"
        >
          Senha:
          <b-input-group class="input-group-merge">
            <b-form-input
              v-model="executivoEdit.senha"
              :type="passwordField"
            />
            <b-input-group-append is-text>
              <feather-icon
                :icon="passwordToggleIcon"
                class="cursor-pointer"
                @click="togglePassword"
              />
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col
          cols="12"
          class="mb-1"
        >
          Ramal:
          <b-form-input
            v-model="executivoEdit.ramal"
          />
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BFormInput,
  BButton,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'
import axios from '@/../axios-auth'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BButton,
    BInputGroup,
    BInputGroupAppend,
  },
  props: {
    item: {
      type: Number,
      default() {
        return 0
      },
    },
    closeModal: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      executivo: {
        funcionarios_id: this.item.id,
        usuario: this.item.nome,
        senha: 123456,
        ramal: '',
        // meta: 0,
      },
      executivoEdit: {
        usuario: null,
        senha: null,
        ramal: null,
      },
      passwordField: 'password',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordField === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    isExecutive() {
      const { funcao } = this.item
      if (funcao === 'Executivo de Cobrança') {
        return true
      }
      return false
    },
  },
  methods: {
    async storeDiscador() {
      await axios.post('api/v1/executivo/store', this.executivo, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
          accept: 'application/json',
        },
      }).then(async () => {
        await this.$swal({
          title: 'Funcionario adicionado ao discador!',
          icon: 'success',
          timer: 1500,
          showConfirmButton: false,
        })
        await this.closeModal()
      })
        .catch(async error => {
          await this.closeModal()
          await this.$swal({
            title: 'ERRO',
            icon: 'error',
            text: error.response.data.mensagem,
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          })
        })
    },
    togglePassword() {
      this.passwordField = this.passwordField === 'password' ? 'text' : 'password'
    },
    editExecutivo() {
      axios.get(`api/v1/funcionarios/executivo/${this.item.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
          accept: 'application/json',
        },
      })
        .then(res => {
          res.data.map(item => {
            this.executivoEdit = {
              usuario: item.usuario,
              senha: item.senha,
              ramal: item.ramal,
            }
          })
          this.$refs.modalEdit.show()
        })
        .catch(error => {
          this.$swal({
            title: 'ERRO',
            icon: 'error',
            text: error.response.data.mensagem,
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          })
        })
    },
    editStore() {
      const body = {
        executivo_id: this.item.id,
        usuario: this.executivoEdit.usuario,
        senha: this.executivoEdit.senha,
        ramal: this.executivoEdit.ramal,
      }
      axios.post('api/v1/funcionarios/executivo/editar', body, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
          accept: 'application/json',
        },
      })
        .then(() => {
          this.$swal({
            title: 'Executivo na fastway editado com sucesso!',
            icon: 'success',
            timer: 1500,
            showConfirmButton: false,
          })
          this.closeModal()
        })
        .catch(error => {
          this.$swal({
            title: 'ERRO',
            icon: 'error',
            text: error.response.data.mensagem,
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          })
        })
    },
  },
}
</script>

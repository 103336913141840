<template>
  <div>
    <b-button
      v-b-modal.indicadorModal
      class="ml-2"
      variant="primary"
    >
      Novo Indicador
    </b-button>
    <b-card>
      <b-overlay
        :show="load"
        variant="transparent"
      >
        <b-table
          :items="items"
          :fields="fields"
        >
          <template #cell(mes)="data">
            {{ resolveMes(data.item.mes) }}
          </template>
          <template #cell(acoes)="data">
            <b-row align-h="center">
              <b-col cols="auto">
                <feather-icon
                  icon="ToolIcon"
                  class="cursor-pointer"
                  @click="editIndicador(data.item.id)"
                />
              </b-col>
              <b-col cols="auto">
                <feather-icon
                  icon="XIcon"
                  class="iconeX cursor-pointer"
                  @click="removeIndicador(data.item.id)"
                />
              </b-col>
            </b-row>
          </template>
        </b-table>
      </b-overlay>
    </b-card>
    <b-modal
      id="indicadorModal"
      ref="indicadorModal"
      :title="indicadorID ? 'Editar Indicador' : 'Cadastrar Indicador'"
      :ok-title=" indicadorID ? 'Atualizar' : 'Cadastrar'"
      cancel-title="Cancelar"
      cancel-variant="danger"
      no-close-on-backdrop
      @cancel="clearForm"
      @ok="indicadorID ? updateIndicador() : submit()"
    >
      <b-row>
        <b-col>
          <label for=""><strong>Mês: </strong></label>
          <v-select
            v-model="mesSelected"
            :options="mesesOptions"
          />
        </b-col>
      </b-row>
      <b-row align-h="around">
        <b-col cols="6">
          <label for=""><strong>Ano: </strong></label>
          <v-select
            v-model="anoSelected"
            :options="anosOptions"
          />
        </b-col>
        <b-col cols="6">
          <label><strong>Acordos: </strong></label>
          <b-form-input
            v-model="acordos"
            type="number"
            placeholder="somente número"
          />
        </b-col>
      </b-row>
      <b-row align-h="around">
        <b-col cols="6">
          <label class="mt-1 mr-1"><strong>Valor:</strong></label>
          <money
            v-model="valor"
            v-bind="money"
          />
        </b-col>
        <b-col cols="6">
          <label><strong>Percentual: </strong></label>
          <b-form-input
            v-model="percentual"
            type="number"
            placeholder="somente número"
          />
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BFormInput,
  BCard,
  BTable,
  BButton,
  BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { Money } from 'v-money'
import axios from '@/../axios-auth'

export default {
  components: {
    BCol,
    BRow,
    BFormInput,
    BCard,
    BTable,
    BButton,
    BOverlay,
    vSelect,
    Money,
  },
  data() {
    return {
      items: [],
      indicadorID: null,
      fields: [
        { key: 'mes', label: 'Mes', class: 'text-center' },
        { key: 'acoes', label: 'AÇÕES', class: 'text-center' },
      ],
      mesSelected: null,
      anoSelected: null,
      acordos: null,
      valor: null,
      percentual: null,
      load: true,
      mesesOptions: [
        { label: 'JANEIRO', value: 1 },
        { label: 'FEVEREIRO', value: 2 },
        { label: 'MARÇO', value: 3 },
        { label: 'ABRIL', value: 4 },
        { label: 'MAIO', value: 5 },
        { label: 'JUNHO', value: 6 },
        { label: 'JULHO', value: 7 },
        { label: 'AGOSTO', value: 8 },
        { label: 'SETEMBRO', value: 9 },
        { label: 'OUTUBRO', value: 10 },
        { label: 'NOVEMBRO', value: 11 },
        { label: 'DEZEMBRO', value: 12 },
      ],
      anosOptions: [
        '2023',
      ],
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2,
        masked: false,
      },
    }
  },
  created() {
    this.getIndicadores()
  },
  methods: {
    clearForm() {
      this.mesSelected = null
      this.anoSelected = null
      this.acordos = null
      this.valor = null
      this.percentual = null
      this.indicadorID = null
    },
    submit() {
      this.load = true
      const body = {
        mes: this.mesSelected.value,
        ano: this.anoSelected,
        acordos: this.acordos,
        valor: this.valor,
        percentual: this.percentual,
      }
      axios.post('api/v1/indicadores/store', body)
        .then(() => {
          this.clearForm()
          this.getIndicadores()
          this.$swal({
            title: 'Novo indicador cadastrado com sucesso!',
            icon: 'success',
            showConfirmButton: false,
          })
        })
        .catch(error => {
          if (error.response) {
            this.clearForm()
            this.getIndicadores()
            this.$swal({
              title: 'Ops, erro ao cadastrar um novo indicador =(',
              icon: 'danger',
              text: error.response.data.mensagem,
              showConfirmButton: false,
              timer: 1500,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
        })
    },
    getIndicadores() {
      this.items = []
      axios.get('api/v1/indicadores')
        .then(res => {
          this.load = false
          res.data.dados.map(item => {
            this.items.unshift(item)
          })
        })
    },
    editIndicador(id) {
      this.load = true
      this.indicadorID = id
      axios.get(`api/v1/indicadores/edit/${id}`)
        .then(res => {
          this.load = false
          this.mesSelected = this.resolveMes(res.data.dados.mes)
          this.anoSelected = res.data.dados.ano
          this.acordos = res.data.dados.acordos
          this.valor = parseFloat(res.data.dados.valor)
          this.percentual = parseFloat(res.data.dados.percentual)
          this.$refs.indicadorModal.show()
        })
        .catch(error => {
          this.load = false
          if (error.response) {
            this.clearForm()
            this.$swal({
              title: 'Ops, algo deu errado =(',
              text: error.response.data.mensagem,
              icon: 'danger',
              showConfirmButton: false,
              timer: 1500,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
        })
    },
    updateIndicador() {
      this.load = true
      const body = {
        mes: this.mesSelected.value,
        ano: this.anoSelected,
        acordos: this.acordos,
        valor: this.valor,
        percentual: this.percentual,
      }
      axios.put(`api/v1/indicadores/update/${this.indicadorID}`, body, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(() => {
          this.clearForm()
          this.getIndicadores()
          this.$swal({
            title: 'Indicador atualizado com sucesso!',
            icon: 'success',
            timer: 1500,
            showConfirmButton: false,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
        .catch(error => {
          if (error.response) {
            this.clearForm()
            this.getIndicadores()
            this.$swal({
              title: 'Ops, algo não foi possível atualizar =(',
              text: error.response.data.mensagem,
              icon: 'danger',
              timer: 1500,
              showConfirmButton: false,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
        })
    },
    resolveMes(mes) {
      const mesObject = this.mesesOptions.find(item => item.value === mes)
      return mesObject.label
    },
    removeIndicador(id) {
      this.$swal({
        title: 'Deseja excluir esse indicador?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, excluir!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios.delete(`api/v1/indicadores/destroy/${id}`)
            .then(() => {
              this.clearForm()
              this.getIndicadores()
              this.$swal({
                title: 'Indicador deletado com sucesso!',
                icon: 'success',
                timer: 1500,
                showConfirmButton: false,
              })
            })
            .catch(() => {
              this.clearForm()
              this.getIndicadores()
              this.$swal({
                title: 'Ops, algo deu errado',
                icon: 'danger',
                timer: 1500,
                showConfirmButton: false,
              })
            })
        }
      })
    },
  },
}
</script>

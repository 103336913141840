<template>
  <div>
    <b-modal
      id="cadastrar-empresa"
      size="lg"
      title="Cadastrar empresa"
      cancel-title="Cancelar"
      ok-title="Cadastrar"
      @ok="cadEmpresa"
      @cancel="limparCampos"
    >
      <b-row>
        <b-col
          lg="6"
          mb="12"
        >
          <b-form-group
            label="Fantasia"
          >
            <b-form-input
              v-model="cad.nome_fantasia"
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="6"
          mb="12"
        >
          <b-form-group
            label="Razão Social"
          >
            <b-form-input
              v-model="cad.razao_social"
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="4"
          mb="12"
        >
          <b-form-group
            label="CNPJ"
          >
            <b-input-group>
              <cleave
                v-model="cad.cnpj"
                class="form-control"
                :options="mask.cnpj"
                placeholder="12.345.678/0001-12"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          lg="4"
          mb="12"
        >
          <b-form-group
            label="Incrição Estadual"
          >
            <b-form-input
              v-model="cad.inscricao_estadual"
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="4"
          mb="12"
        >
          <b-form-group
            label="CEP"
          >
            <b-input-group>
              <cleave
                v-model="cep"
                class="form-control"
                :raw="false"
                :options="mask.cep"
                placeholder="00000-000"
                @keyup.enter="consultaCEP"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-primary"
                  @click="consultaCEP"
                >
                  <feather-icon icon="SearchIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          lg="4"
          mb="12"
        >
          <b-form-group
            label="Cidade"
          >
            <v-select
              v-model="cad.cidade_id"
              :options="opcoesCidades"
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          mb="12"
        >
          <b-form-group
            label="Endereço"
          >
            <b-form-input
              v-model="cad.endereco"
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="4"
          mb="12"
        >
          <b-form-group
            label="Telefone"
          >
            <b-input-group>
              <cleave
                v-model="cad.telefone"
                class="form-control"
                :options="mask.telefone"
                placeholder="(00) 2345-6789"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          lg="4"
          mb="12"
        >
          <b-form-group
            label="Celular"
          >
            <b-input-group>
              <cleave
                v-model="cad.celular"
                class="form-control"
                :options="mask.celular"
                placeholder="(00) 1 2345-6789"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          lg="4"
          mb="12"
        >
          <b-form-group
            label="Email"
          >
            <b-form-input
              v-model="cad.email"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
    <b-row>
      <b-button
        v-b-modal.cadastrar-empresa
        class="mr-1 mt-2"
        variant="primary"
        @click="limparCampos"
      >
        <span class="justify-content-center">Cadastrar Empresa</span>
      </b-button>
    </b-row>
    <b-row class="mt-2">
      <b-col>
        <b-row align-h="between">
          <b-col
            cols="1"
            class="mb-2"
          >
            <b-form-select
              v-model="perPage"
              size="sm"
              :options="[10, 15, 20]"
            />
          </b-col>
          <b-col
            lg="8"
          >
            <b-form-input
              v-model="filter"
              placeholder="Pesquisar"
              type="search"
              size="sm"
            />
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12">
        <b-table
          select-mode="single"
          responsive
          bordered
          striped
          hover
          :filter="filter"
          :per-page="perPage"
          :current-page="currentPage"
          :fields="fieldsEmpresa"
          :items="itemsEmpresa"
          @row-clicked="onRowSelected"
        />
      </b-col>
      <b-col cols="12">
        <b-pagination
          v-model="currentPage"
          :per-page="perPage"
          :total-rows="totalRows"
          align="center"
          size="sm"
        />
      </b-col>
    </b-row>
    <b-modal
      id="modalEmpresa"
      ref="modalEmpresa"
      title="Editar Empresa"
      size="lg"
      hide-footer
    >
      <b-row v-if="!editar">
        <b-col
          lg="6"
          mb="12"
        >
          <b-form-group
            label="Nome Fantasia"
          >
            <b-form-input
              v-model="empresa.nome_fantasia"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="6"
          mb="12"
        >
          <b-form-group
            label="Razão Social"
          >
            <b-form-input
              v-model="empresa.razao_social"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="4"
          mb="12"
        >
          <b-form-group
            label="CNPJ"
          >
            <b-input-group>
              <cleave
                v-model="empresa.cnpj"
                readonly
                class="form-control"
                :options="mask.cnpj"
                placeholder="12.345.678/0001-12"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          lg="4"
          mb="12"
        >
          <b-form-group
            label="Incrição Estadual"
          >
            <b-form-input
              v-model="empresa.inscricao_estadual"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="4"
          mb="12"
        >
          <b-form-group
            label="Cidade"
          >
            <v-select
              v-model="cidade_selected"
              :options="opcoesCidades"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          mb="12"
        >
          <b-form-group
            label="Endereço"
          >
            <b-form-input
              v-model="empresa.endereco"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="4"
          mb="12"
        >
          <b-form-group
            label="Telefone"
          >
            <b-input-group>
              <cleave
                v-model="empresa.telefone"
                readonly
                class="form-control"
                :options="mask.telefone"
                placeholder="(00) 2345-6789"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          lg="4"
          mb="12"
        >
          <b-form-group
            label="Celular"
          >
            <b-input-group>
              <cleave
                v-model="empresa.celular"
                readonly
                class="form-control"
                :options="mask.celular"
                placeholder="(00) 1 2345-6789"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          lg="4"
          mb="12"
        >
          <b-form-group
            label="Email"
          >
            <b-form-input
              v-model="empresa.email"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Ativo"
          >
            <b-form-checkbox
              v-model="empresa.status"
              disabled
              switch
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="editar">
        <b-col
          lg="6"
          mb="12"
        >
          <b-form-group
            label="Nome Fantasia"
          >
            <b-form-input
              v-model="empresa.nome_fantasia"
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="6"
          mb="12"
        >
          <b-form-group
            label="Razão Social"
          >
            <b-form-input
              v-model="empresa.razao_social"
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="4"
          mb="12"
        >
          <b-form-group
            label="CNPJ"
          >
            <b-input-group>
              <cleave
                v-model="empresa.cnpj"
                class="form-control"
                :options="mask.cnpj"
                placeholder="12.345.678/0001-12"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          lg="4"
          mb="12"
        >
          <b-form-group
            label="Incrição Estadual"
          >
            <b-form-input
              v-model="empresa.inscricao_estadual"
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="4"
          mb="12"
        >
          <b-form-group
            label="Cidade"
          >
            <v-select
              v-model="cidade_selected"
              :options="opcoesCidades"
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          mb="12"
        >
          <b-form-group
            label="Endereço"
          >
            <b-form-input
              v-model="empresa.endereco"
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="4"
          mb="12"
        >
          <b-form-group
            label="Telefone"
          >
            <b-input-group>
              <cleave
                v-model="empresa.telefone"
                class="form-control"
                :options="mask.telefone"
                placeholder="(00) 2345-6789"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          lg="4"
          mb="12"
        >
          <b-form-group
            label="Celular"
          >
            <b-input-group>
              <cleave
                v-model="empresa.celular"
                class="form-control"
                :options="mask.celular"
                placeholder="(00) 1 2345-6789"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          lg="4"
          mb="12"
        >
          <b-form-group
            label="Email"
          >
            <b-form-input
              v-model="empresa.email"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Ativo"
          >
            <b-form-checkbox
              v-model="empresa.status"
              switch
            />
          </b-form-group>
        </b-col>
      </b-row>
      <hr>
      <b-row>
        <b-col>
          <b-button
            v-show="!editar"
            variant="outline-primary"
            @click="btEditarEmpresa"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
            />
            <span>Editar</span>
          </b-button>
        </b-col>
        <b-col>
          <b-button
            variant="outline-danger"
            @click="deleteEmpresa(empresa.nome_fantasia)"
          >
            <feather-icon
              icon="TrashIcon"
              class="mr-50"
            />
            <span>Apagar</span>
          </b-button>
        </b-col>
        <b-col>
          <b-button
            variant="outline-secondary"
            @click="$refs.modalEmpresa.hide()"
          >
            <feather-icon
              icon="XIcon"
              class="mr-50"
            />
            <span>Cancelar</span>
          </b-button>
        </b-col>
        <b-col>
          <b-button
            v-show="editar"
            variant="primary"
            @click="editEmpresa(idEmpresa)"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />
            <span>Salvar</span>
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
import {
  BRow,
  BModal,
  VBModal,
  BCol,
  BTable,
  BFormInput,
  BFormGroup,
  BButton,
  BFormSelect,
  BFormCheckbox,
  BInputGroupAppend,
  BPagination,
  BInputGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import axios from '@/../axios-auth'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

export default {
  components: {
    Cleave,
    BRow,
    BModal,
    BCol,
    BTable,
    BFormInput,
    BFormGroup,
    BInputGroupAppend,
    BButton,
    BFormSelect,
    BFormCheckbox,
    BPagination,
    BInputGroup,
    vSelect,
    FeatherIcon,
  },

  directives: {
    'b-modal': VBModal,
  },

  data() {
    return {
      filter: null,
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
      fieldsEmpresa: [],
      itemsEmpresa: [],
      cep: null,
      cidade_selected: {
        id: null,
        label: null,
      },
      empresa: {
        nome_fantasia: '',
        razao_social: '',
        cnpj: '',
        inscricao_estadual: '',
        cidade_id: '',
        endereco: '',
        telefone: '',
        celular: '',
        email: '',
        status: '',
      },
      idEmpresa: null,
      editar: false,
      cad: {
        nome_fantasia: '',
        razao_social: '',
        cnpj: '',
        inscricao_estadual: '',
        cidade_id: '',
        endereco: '',
        telefone: '',
        celular: '',
        email: '',
        status: true,
      },
      opcoesCidades: [],
      mask: {
        cep: {
          delimiters: ['-'],
          blocks: [5, 3],
        },
        cnpj: {
          delimiters: ['.', '.', '/', '-'],
          blocks: [2, 3, 3, 4, 2],
          uppercase: true,
        },
        telefone: {
          delimiters: ['(', ')', ' ', '-', '-'],
          blocks: [0, 2, 0, 4, 4],
          uppercase: true,
        },
        celular: {
          delimiters: ['(', ')', ' ', ' ', '-', '-'],
          blocks: [0, 2, 0, 1, 4, 4],
          uppercase: true,
        },
      },
    }
  },

  created() {
    this.listarEmpresas()
    this.listarCidades()
  },

  methods: {
    async listarEmpresas() {
      this.itemsEmpresa = []
      await axios.get('api/v1/empresas', {
        headers: {
          Accept: 'Application/json',
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        },
      }).then(async res => {
        await res.data.dados.empresa.map(empresa => (
          this.itemsEmpresa.push({
            id: empresa.id,
            fantasia: empresa.nome_fantasia,
            razao: empresa.razao_social,
          })
        ))
        this.totalRows = this.itemsEmpresa.length
      })
    },

    async listarCidades() {
      this.opcoesCidades = []
      await axios.get('api/v1/cidades', {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(async res => {
        await res.data.dados.map(cidade => (
          this.opcoesCidades.push({
            label: cidade.nome,
            id: cidade.id,
          })
        ))
      })
    },

    onRowSelected(item) {
      this.getEmpresa(item.id)
    },

    async getEmpresa(id) {
      this.empresa = []
      this.idEmpresa = null
      await axios.get(`api/v1/empresas/edit/${id}`, {
        headers: {
          Acccpet: 'application/json',
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        },
      }).then(res => {
        const dt = res.data.dados.empresa[0]
        this.empresa = {
          nome_fantasia: dt.nome_fantasia,
          razao_social: dt.razao_social,
          cnpj: dt.cnpj,
          inscricao_estadual: dt.inscricao_estadual,
          cidade_id: dt.cidade_id,
          endereco: dt.endereco,
          telefone: dt.telefone,
          celular: dt.telefone,
          email: dt.email,
          status: dt.status,
        }
        this.cidade_selected.id = dt.cidade_id
        this.cidade_selected.label = dt.cidade.nome
      })
      this.idEmpresa = id
      this.editar = false
      this.$refs.modalEmpresa.show()
    },

    limparCampos() {
      this.cad = []
    },

    async deleteEmpresa(nome) {
      this.$swal({
        title: 'Tem certeza?',
        text: `Deseja deletar a empresa ${nome}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim quero deletar!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios.delete(`api/v1/empresas/delete/${this.idEmpresa}`, {
            headers: {
              Authorization: 'Bearer '.concat(localStorage.getItem('token')),
              accept: 'application/json',
            },
          }).then(() => {
            this.listarEmpresas()
            this.$refs.modalEmpresa.hide()
            this.$swal({
              icon: 'success',
              title: 'Apagado!',
              text: 'Empresa apagada com sucesso!',
              timer: 1500,
              showConfirmButton: false,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }).catch(error => {
            this.$swal({
              title: 'Erro ao Apagar!',
              text: error,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          })
        }
      })
    },

    async editEmpresa(id) {
      this.empresa.cidade_id = this.cidade_selected.id
      await axios.put(`api/v1/empresas/update/${id}`, this.empresa, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(() => {
        this.listarEmpresas()
        this.$refs.modalEmpresa.hide()
        this.$swal({
          icon: 'success',
          title: 'Salvo!',
          text: 'Empresa salva com sucesso!',
          timer: 1500,
          showConfirmButton: false,
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
      }).catch(error => {
        this.$swal({
          title: 'Erro ao Salvar!',
          text: error,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
    },

    async cadEmpresa() {
      const formData = {
        nome_fantasia: this.cad.nome_fantasia,
        razao_social: this.cad.razao_social,
        cnpj: this.cad.cnpj,
        inscricao_estadual: this.cad.inscricao_estadual,
        cidade_id: this.cad.cidade_id.id,
        endereco: this.cad.endereco,
        telefone: this.cad.telefone,
        celular: this.cad.celular,
        email: this.cad.email,
        status: true,
      }
      await axios.post('api/v1/empresas/store', formData, {
        headers: {
          Accept: 'Application/json',
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        },
      }).then(() => {
        this.listarEmpresas()
        this.$swal({
          icon: 'success',
          title: 'Cadastrado!',
          text: 'Empresa cadastrada com sucesso!',
          timer: 1500,
          showConfirmButton: false,
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
      }).catch(error => {
        this.$swal({
          title: 'Erro ao cadastrar!',
          text: error,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
    },

    async consultaCEP() {
      const cep = this.cep.replace(/[^0-9]/g, '')
      await axios.get(`https://viacep.com.br/ws/${cep}/json/`)
        .then(res => {
          this.cad.endereco = res.data.logradouro.concat(` ${res.data.bairro}`)
          this.cad.cidade = res.data.localidade
        })
    },
    btEditarEmpresa() {
      this.editar = true
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
